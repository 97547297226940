// src/components/CustomInputBox/CustomInputBox.js
import React, { useEffect, useState } from 'react';

// constants
import { COLORS } from '../../constants'

import './CustomInputBox.css'


function CustomInputBox({ callback, data, placeholder, width, backgroundColor, border, fontColor, padding, textLength, errorText, icon, iconHandler, styles }) {

    const [text, setText] = useState(data || ''); // 초기값 설정
    
    useEffect(() => {
        // console.log(data);
        setText(data || '');
    }, [data]);

    const handleChange = async (event)=> {
        setText(event.target.value);
        callback(event.target.value);
    }
    

    return (
        <>
        <div className="customTextArea" style={{ ...styles,
        width:width, 
        backgroundColor:backgroundColor, 
        border:errorText? "1px solid red":border, 
        color:fontColor, 
        padding: padding, 
        borderRadius:'4px', 
        display:'flex', 
        flexDirection:'row',
        justifyContent:'space-between',
        boxSizing:'border-box' }} >   
            <input
            type="text"
            value={text}
            className="CustomSelectBoxContainer" 
            onChange={handleChange} 
            style={{
              padding: 0,
              border: 'none',
              outline: 'none',
              ...textLength ? {flex: 1} : {width: '100%'}
            }}
            maxLength={textLength}
            placeholder={placeholder}
            />
            {textLength && <span className="textLengthBox"><span>{data? data.length:0}</span>/{textLength}자</span>}
            {icon &&  <img className="customInputIcon" onClick={iconHandler} src={icon} style={{cursor: 'pointer'}} />}
        </div>
        {errorText && <p style={{color:'red', fontSize:'12px', marginTop:'8px'}}>{errorText}</p>}
        </>
    );
}

export default CustomInputBox;
