import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

import "react-datepicker/dist/react-datepicker.css";

// contexts
import { useAuth } from '../../contexts/AuthContext';

// constants
import { COLORS } from '../../constants'

// Components
import { CustomContentsTitle, CustomInputBox, CustomTextArea, CustomDateSelect, CustomButtonRadio, 
    CustomButton, CustomImageMultiUpload, CustomHourSelect, CustomMinSelect,
    LoadingSpinner } from "../../components"


import './WorshipEditorPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

const CustomButtonRadioData = [
    {"name":"주일 예배", "value":1}, 
    {"name":"주중 예배", "value":0}
]

function WorshipEditorPage(){

    const { isAuthenticated, checkAuth, accessToken, logout, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState(false);

    const { id } = useParams();
    const { communityId } = useAuth();
    const [isUploading, setIsUploading] = useState(false);


    const [uploadComplete, setUploadComplete] = useState(false);

    const [editWorship, setEditWorship] = useState(null);

    const [worshipTitle,setWorshipTitle] = useState(null);
    const [worshipTitleError,setWorshipTitleError] = useState(null);
    const [worshipDesc,setWorshipDesc] = useState(null);
    const [worshipScripture,setWorshipScripture] = useState(null);
    const [worshipSpeaker,setWorshipSpeaker] = useState(null);
    const [worshipDate,setWorshipDate] = useState(null);
    const [worshipDateError,setWorshipDateError] = useState(null);
    const [worshipHour,setWorshipHour] = useState(null);
    const [worshipHourError,setWorshipHourError] = useState(null);
    const [worshipMin,setWorshipMin] = useState(null);
    const [worshipMinError,setWorshipMinError] = useState(null);

    const [isSundayWorship,setIsSundayWorship] = useState(null);
    const [isSundayWorshipError,setIsSundayWorshipError] = useState(null);

    const [sermonImageFiles, setSermonImageFiles] = useState([]);
    const [sermonImageUrls, setSermonImageUrls] = useState([]);
    const [sermonImageCount, setSermonImageCount] = useState(0);
    const [sermonImageError, setSermonImageError] = useState(null);

    const [worshipOrderImageFiles, setWorshipOrderImageFiles] = useState([]);
    const [worshipOrderImageUrls, setWorshipOrderImageUrls] = useState([]);
    const [worshipOrderImageCount, setWorshipOrderImageCount] = useState(0);
    const [worshipOrderImageError, setWorshipOrderImageError] = useState(null);

    const [praiseImageFiles, setPraiseImageFiles] = useState([]);
    const [praiseImageUrls, setPraiseImageUrls] = useState([]);
    const [praiseImageCount, setPraiseImageCount] = useState(0);

    const [worshipLiveVideoUrl,setWorshipLiveVideoUrl] = useState(null);
    const [worshipSermonVideoUrl,setWorshipSermonVideoUrl] = useState(null);
    const [worshipPraiseVideoUrl,setWorshipPraiseVideoUrl] = useState(null);

    const [buttonTitle,setButtonTitle] = useState(null);
    const [buttonUrl,setButtonUrl] = useState(null);

    const [validation,setValidation] = useState(false);

    useEffect(()=>{
        setLoading(false);
    },[]);

    useEffect(() => {
        if(isAuthenticated) {
            if(id) {
                get_worship(id);
            }
            else setInitData(true);
        };
    }, [isAuthenticated, accessToken]);
    
    useEffect(()=>{
        if(editWorship){
            setWorshipTitle(editWorship.title);
            setWorshipDesc(editWorship.description);
            setWorshipScripture(editWorship.scripture);
            setWorshipSpeaker(editWorship.speaker);
            setWorshipDate(new Date(editWorship.worshipDate));
            setWorshipHour(new Date(editWorship.worshipDate).getHours());
            setWorshipMin(new Date(editWorship.worshipDate).getMinutes());
            setIsSundayWorship(editWorship.sundayWorship);
            setSermonImageUrls(editWorship.sermonImageUrl? stringToArray(editWorship.sermonImageUrl):[]);
            setPraiseImageUrls(editWorship.praiseImageUrl? stringToArray(editWorship.praiseImageUrl):[]);
            setWorshipOrderImageUrls(editWorship.worshipOrderImageUrl? stringToArray(editWorship.worshipOrderImageUrl):[]);
            setWorshipLiveVideoUrl(editWorship.liveVideoUrl);
            setWorshipSermonVideoUrl(editWorship.sermonVideoUrl);
            setWorshipPraiseVideoUrl(editWorship.praiseVideoUrl);
            setInitData(true);
        }
    },[editWorship]);

    useEffect(()=>{
        if(uploadComplete){
            navigate('/worship/list', { replace: true });
        }
    },[uploadComplete]);
    
    useEffect(()=>{
        let result = dataValidate();
        if(result){
            setValidation(true); 
        }
        else {
            setValidation(false); 
        }
    },[worshipTitle,worshipDate,isSundayWorship,sermonImageCount])


    const dataValidate = () => {
        if(worshipTitle!=null&&worshipTitle.length>0&&worshipDate!=null&&isSundayWorship!=null&&sermonImageCount>0) return true;
        return false;
    }

    const handleWorshipTitle = (title) => {
        setWorshipTitle(title);
        setWorshipTitleError(null);
    }
    const handleWorshipDesc = (desc) => {
        setWorshipDesc(desc);
    }
    const handleWorshipScripture = (scripture) => {
        setWorshipScripture(scripture);
    }
    const handleWorshipSpeaker = (speaker) => {
        setWorshipSpeaker(speaker);
    }
    const handleWorshipDate = (worshipDate) => {
        setWorshipDate(worshipDate);
        setWorshipDateError(null);
    }
    const handleWorshipHour = (worshipHour) => {
        setWorshipHour(worshipHour);
        setWorshipHourError(null);
    }
    const handleWorshipMin = (worshipMin) => {
        setWorshipMin(worshipMin);
        setWorshipMinError(null);
    }
    const handleIsSundayWorship = (isSunday) => {
        setIsSundayWorship(isSunday);
        setIsSundayWorshipError(null);
    }
    const handleSermonImageFiles = (file,url) => {
        setSermonImageFiles(file);
        setSermonImageUrls(url);
        setSermonImageError(null);
    }
    const handleWorshipOrderImageFiles = (file,url) => {
        setWorshipOrderImageFiles(file);
        setWorshipOrderImageUrls(url);
        setWorshipOrderImageError(null);
    }
    const handlePraiseImageFiles = (file,url) => {
        setPraiseImageFiles(file);
        setPraiseImageUrls(url);
    }    
    const handleWorshipLiveVideoUrl = (url) => {
        setWorshipLiveVideoUrl(url);
    }
    const handleWorshipSermonVideoUrl = (url) => {
        setWorshipSermonVideoUrl(url);
    }
    const handleWorshipPraiseVideoUrl = (url) => {
        setWorshipPraiseVideoUrl(url);
    }
    const handleButtonTitle = (title) => {
        setButtonTitle(title);
    }
    const handleButtonUrl = (url) => {
        setButtonUrl(url);
    }
    
    useEffect(()=>{
        setSermonImageCount(sermonImageUrls.length);
    },[sermonImageUrls]);
    
    useEffect(()=>{
        setPraiseImageCount(praiseImageUrls.length);
    },[praiseImageUrls]);
    
    useEffect(()=>{
        setWorshipOrderImageCount(worshipOrderImageUrls.length);
    },[worshipOrderImageUrls]);

    

    const upload = async() => {        
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 

        setLoading(true);
        let state = true;

        if(!communityId) {
            console.warning("커뮤니티 정보가 없습니다.");
            return;
        }
        
        // 설교 제목
        if(worshipTitle==null || worshipTitle.length==0) {
            setWorshipTitleError("제목을 입력해주세요");
            state &= false;
        } else{
            setWorshipTitleError(null);
            state &= true;
        }

        // 예배 날짜
        if(worshipDate==null || worshipDate.length==0) {
            setWorshipDateError("날짜를 입력해주세요");
            state &= false;
        } else{
            setWorshipDateError(null);
            state &= true;
        }

        // 예배 시간
        if(worshipHour==null) {
            setWorshipHourError("시간을 입력해주세요.");
            state &= false;
        } else{
            setWorshipHourError(null);
            state &= true;
        }

        // 예배 분
        if(worshipMin==null) {
            setWorshipMinError("분을 입력해주세요.");
            state &= false;
        } else{
            setWorshipMinError(null);
            state &= true;
        }

        if(!state) {
            setLoading(false);
            return;
        }

        // 주일예배 여부
        if(isSundayWorship==null || isSundayWorship.length==0) {
            setIsSundayWorshipError("주일예배 여부를 선택해주세요");
            state = false;
            setLoading(false);
            return;
        } else{
            setIsSundayWorshipError(null);
            state = true;
        }

        // 말씀 이미지 여부
        if(sermonImageUrls==[] || sermonImageUrls.length==0) {
            setSermonImageError("말씀 이미지를 추가해주세요");
            state = false;
            setLoading(false);
            return;
        } else{
            setSermonImageError(null);
            state = true;
        } 
        
        if(state){
            setIsUploading(true);

            let sermonUrlsTemp = [];
            if (sermonImageUrls) {
                const sermonImageUrlsWithIndex = sermonImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                sermonImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    sermonUrlsTemp.push(temp);
                });
            }

            let worshipOrderUrlsTemp = [];
            if (worshipOrderImageUrls) {
                const worshipOrderImageUrlsWithIndex = worshipOrderImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                worshipOrderImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    worshipOrderUrlsTemp.push(temp);
                });
            }

            let praiseUrlsTemp = [];
            if (praiseImageUrls) {
                const praiseImageUrlsWithIndex = praiseImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                praiseImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    praiseUrlsTemp.push(temp);
                });
            }
            
            const data = {
                communityId: communityId,
                title: worshipTitle,
                description: worshipDesc,
                scripture: worshipScripture,
                speaker: worshipSpeaker,
                worshipDate: getKSTISOString(worshipDate,worshipHour,worshipMin),
                sundayWorship: isSundayWorship,
                liveVideoUrl: worshipLiveVideoUrl,
                sermonVideoUrl: worshipSermonVideoUrl,
                praiseVideoUrl: worshipPraiseVideoUrl,
                sermonImageUrl: sermonUrlsTemp,
                praiseImageUrl: praiseUrlsTemp,
                worshipOrderImageUrl: worshipOrderUrlsTemp,
            }
            update_worships(data);
        }
    }

    const get_worship = async (worshipId) => {
        try {
            const response = await axios.get(API_SERVER+'/WORSHIPS/'+worshipId);
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log("[get_worship]","[GET]",API_SERVER+'/WORSHIPS/'+worshipId,responseData[0]);
                setEditWorship(responseData[0]);
            } else {
                console.log("Failed login");
            }
        } catch (error) {
            if (error.response) {
                console.error("error",error.response);
            } else if (error.request) {
                console.error('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.error('로그인 실패: ' + error.message);
            }
            logout(()=>{navigate("/login")});
        } finally {
            setLoading(false);
        }
    }
    const update_worships = async (data) => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 

        try {
            let response;

            const formData = new FormData();
            formData.append("data", JSON.stringify(data)); 

            if (sermonImageFiles) {
                sermonImageFiles.forEach((file) => formData.append("sermonImageFile", file));
            }
            if (worshipOrderImageFiles) {
                worshipOrderImageFiles.forEach((file) => formData.append("worshipOrderImageFile", file));
            }
            if (praiseImageFiles) {
                praiseImageFiles.forEach((file) => formData.append("praiseImageFile", file));
            }

            if (id){
                if(ENV) console.log("[PUT]",API_SERVER+'/worships/'+id,formData);
                response = await axios.put(API_SERVER+'/worships/'+id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }
            else{
                if(ENV) console.log("[POST]",API_SERVER+'/worships',formData);
                response = await axios.post(API_SERVER + '/worships', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }
            
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log(responseData);
                // setWorshipList(responseData);
                setIsUploading(false);
                setLoading(false);
                setUploadComplete(true);
            } else {
                console.log("Failed to add worships");
                setLoading(false);
            }
        } catch (error) {
            if (error.response) {
                console.log("error",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.log('로그인 실패: ' + error.message);
            }
            setLoading(false);
        }
    }

    const getKSTISOString = (date, hour, min) => {
        // bulletinDate가 문자열이면 Date 객체로 변환
        const dateTemp = typeof date === 'string' ? new Date(date) : date;
    
        // 시간과 분 설정
        dateTemp.setHours(hour);
        dateTemp.setMinutes(min);
    
        // UTC 기준에서 한국 시간으로 변환
        const kstDate = new Date(dateTemp);
    
        // ISO 문자열 반환
        return new Date(dateTemp).toISOString();
    };
    const stringToArray = (str) => {
        if (typeof str !== "string") return [];

        // 빈 배열 형태의 문자열 처리
        if (str.trim() === "[]") return [];

        // 정상적인 URL,URL,URL 형태의 문자열을 배열로 변환
        return str.split(",").map((item) => item.trim());
    }

    if(!initData) return;

    return (
        <div className="contentsPage worshipEditorWrap">
            {loading&& <LoadingSpinner/>}
            <div className="worshipEditorContainer">
                {/* 제목 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"제목"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <CustomInputBox 
                    callback={(title)=> {handleWorshipTitle(title)}}
                    data={worshipTitle}
                    placeholder={"제목 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    textLength={30}
                    errorText={worshipTitleError}/>
                </div>

                {/* 설명 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"설명"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomTextArea 
                    callback={(desc)=>{handleWorshipDesc(desc)}}
                    data={worshipDesc? worshipDesc: ""}
                    placeholder={"설명 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    line={10}
                    textLength={2000}/>
                </div>

                {/* 말씀 본문 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"말씀 본문"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}/>
                    <CustomInputBox 
                    callback={(scripture)=>{handleWorshipScripture(scripture)}}
                    data={worshipScripture}
                    placeholder={"말씀 본문 입력"}
                    width={"122px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}/>
                </div>

                {/* 설교자 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"설교자"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}/>
                    <CustomInputBox 
                    callback={(speaker)=>{handleWorshipSpeaker(speaker)}}
                    data={worshipSpeaker}
                    placeholder={"설교자 입력"}
                    width={"122px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}/>
                </div>

                {/* 예배 시간 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"예배 시간"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>
                        <CustomDateSelect 
                        callback={(date)=>{handleWorshipDate(date)}}
                        data={worshipDate}
                        width={"124px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={worshipDateError}/>
                        <CustomHourSelect 
                        callback={handleWorshipHour}
                        data={worshipHour}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={worshipHourError}/>
                        <CustomMinSelect 
                        callback={handleWorshipMin}
                        data={worshipMin}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={worshipMinError}/>
                    </div>
                </div>

                {/* 주일예배 여부 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"주일예배 여부"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <CustomButtonRadio 
                    callback={(isSunday)=>{handleIsSundayWorship(isSunday)}}
                    label={CustomButtonRadioData}
                    data={isSundayWorship}
                    width={"auto"}
                    border={"1px solid "+ COLORS.grayD}
                    backgroundColor={COLORS.white}
                    fontColor={COLORS.black9}
                    padding={"8px 16px"}
                    errorText={isSundayWorshipError}/>
                </div>

                {/* 말씀 이미지 */}
                <div className="worshipEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"말씀 이미지"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={sermonImageCount}
                            lengthMax={2}
                            require={true} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handleSermonImageFiles(images,urls)}}
                            imagesUrl={sermonImageUrls}
                            imageFile={sermonImageFiles}
                            id={"SermonImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={2}
                            errorText={sermonImageError} />
                        </span>
                    </div>
                </div>
                {/* 예배순서 이미지 */}
                <div className="worshipEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"예배순서 이미지"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={worshipOrderImageCount}
                            lengthMax={2}
                            require={true} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handleWorshipOrderImageFiles(images,urls)}}
                            imagesUrl={worshipOrderImageUrls}
                            imageFile={worshipOrderImageFiles}
                            id={"WroshipOrderImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={2}
                            errorText={worshipOrderImageError} />
                        </span>
                    </div>
                </div>
                {/* 찬양 이미지 */}
                <div className="worshipEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"찬양 이미지"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={praiseImageCount}
                            lengthMax={1} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handlePraiseImageFiles(images,urls)}}
                            imagesUrl={praiseImageUrls}
                            imageFile={praiseImageFiles}
                            id={"PraiseImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={1} />
                        </span>
                    </div>
                </div>

                {/* 실시간 스트리밍 URL */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"실시간 스트리밍 URL"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500} />
                    <CustomInputBox 
                    callback={handleWorshipLiveVideoUrl}
                    data={worshipLiveVideoUrl}
                    placeholder={"실시간 스트리밍 URL"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"} />
                </div>

                {/* 말씀 동영상 URL */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"말씀 동영상 URL"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500} />
                    <CustomInputBox 
                    callback={handleWorshipSermonVideoUrl}
                    data={worshipSermonVideoUrl}
                    placeholder={"말씀 동영상 URL 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"} />
                </div>

                {/* 찬양 동영상 URL */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"찬양 동영상 URL"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500} />
                    <CustomInputBox 
                    callback={handleWorshipPraiseVideoUrl}
                    data={worshipPraiseVideoUrl}
                    placeholder={"찬양 동영상 URL 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"} />
                </div>

                {/* 버튼 제목 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"버튼 제목"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomInputBox 
                    callback={handleButtonTitle}
                    data={buttonTitle}
                    placeholder={"버튼 제목 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    textLength={20} />
                </div>

                <div className="worshipEditorContentsRow">
                    <div className="urlPriority">
                    <p>* 주보에는 아래 순으로 노출됩니다. (없으면 다음 순위의 URL로 이동됨) </p>
                    <p>1순위: 말씀 동영상 URL </p>
                    <p>2순위: 실시간 스트리밍 URL </p>
                    <p>3순위: SNS청년부 Youtube 채널 URL</p>
                    </div>
                </div>

                {/* 버튼 URL */}
                {/* <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"버튼 URL"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500} />
                    <CustomInputBox 
                    callback={handleButtonUrl}
                    data={buttonUrl}
                    placeholder={"버튼 URL 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"} />
                </div> */}
                
                {Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r))&&
                <div className="worshipEditorContentsRow contentsButtonBox">
                    <CustomButton 
                    title={"취소"}
                    onClick={() => {navigate(-1)}}
                    width={"auto"}
                    padding={"8px 16px"}
                    backgroundColor={COLORS.white}
                    border={"1px solid"+COLORS.black3} />
                    <CustomButton 
                    title={"등록"}
                    onClick={upload}
                    width={"auto"}
                    padding={"8px 16px"}
                    fontColor={validation? COLORS.white:COLORS.black9}
                    backgroundColor={validation? COLORS.black3:COLORS.grayED}
                    border={"1px solid"+validation? COLORS.black3:COLORS.grayED}/>
                </div>
                }

            </div>
        </div>
    );
}
export default WorshipEditorPage;