import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTable, useRowSelect, usePagination } from 'react-table';
import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// contexts
import { useAuth } from '../../../contexts/AuthContext';

// constants
import { COLORS } from '../../../constants'

// Components
import { CustomSelectBox, CustomTitle, CustomTable, HorizenLine } from "../../../components"


import './SettingsAdminPage.css'

const API_SERVER = process.env.REACT_APP_API_URLS;


function SettingsAdminListPage(){
        
    const { isAuthenticated, accessToken, logout } = useAuth();
    let navigate = useNavigate();
    const [selectedTab,setSelectedTab] = useState(0);

    const [userList, setUserList] = useState([]);
    const userListColumn = useMemo(() => [
        { accessor: "username", Header: "이름", Cell: ({ value }) => <div className="tdBox" style={{ width: '87px' }}>{value}</div>, },
        { accessor: "loginId", Header: "이메일", Cell: ({ value }) => <div className="tdBox" style={{ width: '231px' }}>{value}</div>, },
        { accessor: "createdAt", Header: "등록일시", Cell: ({ value }) => <div className="tdBox" style={{ width: '217px' }}>{formatDate(value)}</div>, },
        { accessor: "role", Header: "역할", Cell: ({ value }) => <div className="tdBox" style={{ width: '217px' }}>{formatRole(value)}</div>, },
    ],[]);


    useEffect(() => {
        // console.log("SettingsAdminListPage");
        const get_users = async () => {
            try {
                const response = await axios.get(API_SERVER+'/USERS');
                if (response.status === 200) {
                    const responseData = response.data.data;
                    // console.log(responseData);
                    setUserList(responseData);
                } else {
                    console.log("failed login");
                }
            } catch (error) {
                console.log("error",error);
                if (error.response) {
                    console.log("error.response",error.response);
                } else if (error.request) {
                    console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                } else {
                    console.log('로그인 실패: ' + error.message);
                }
                logout(()=>{navigate("/login")});
            }
        }
        if(isAuthenticated) get_users();
    }, [isAuthenticated, accessToken]);

    const invite_admin = () => {
        console.log("invite_admin");
        navigate("/settings/admin/invite")
    }

    const formatDate = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hour = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const minute = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();
        return `${year}.${month}.${day} ${hour}:${minute}`;
    }

    const formatRole = (role) => {
        const roleObj = role.split(',').map(role => role.trim());
        let roleText = "";
        for(let i=0; i<roleObj.length; i++){
            if(roleObj[i]=="admin") roleText += "관리자";
            if(roleObj[i]=="bulletin") roleText += "주보 담당자";
            if(roleObj[i]=="push") roleText +=  "푸시 알람 담당자";
            if(i<roleObj.length-1) roleText += ", ";
        }
        return roleText;
    }

    return (
        <div className="contentsPage settingsAdminListWrap">
            
            {/* Title Container */}
            <CustomTitle 
                title="관리자" 
                titleSize="24px"
                titleWeight="500"

                // buttonTitle="관리자 초대" 
                // buttonOnClick={invite_admin}
                // buttonBackgroundColor={COLORS.black3} 
                // buttonFontColor={COLORS.white} 
                // buttonPadding="8px 16px"
                // buttonBorder={"1px solid "+ COLORS.black3}
            />
            <HorizenLine color="#999999" />
            

            {/* Tab */}
            <div className="tabContainer">
                {/* <span className={selectedTab==0? "selected categoryTab":"categoryTab"} onClick={()=>{setSelectedTab(0)}}>등록된 관리자</span>
                <span className={selectedTab==1? "selected categoryTab":"categoryTab"} onClick={()=>{setSelectedTab(1)}}>초대중 관리자</span> */}
            </div>

            {/* 리스트 */}
            <div className="tableContainer">
                {selectedTab==0 && 
                <CustomTable
                    data={userList}
                    columns={userListColumn}
                    onEdit={()=>{}}
                    onDelete={()=>{}}
                    listDot={false} />
                }
            </div>
        </div>
    );
}
export default SettingsAdminListPage;


