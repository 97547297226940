// src/components/CustomButtonRadio/CustomButtonRadio.js
import React, { useEffect, useState } from 'react';

import './CustomButtonRadio.css'


function CustomButtonRadio({ callback, label, data, width, backgroundColor, border, fontColor, padding, errorText }) {

    const [selectedValue, setSelectedValue] = useState();
    const [errorTextIdx, setErrorTextIdx] = useState();

    useEffect(()=>{
        if(data!=null) {
            setSelectedValue(data)
        }
    },[data]);

    useEffect(()=>{
        if(errorText==null) setErrorTextIdx(false)
        else setErrorTextIdx(true)
    },[errorText])


    const handleChange = async (value)=> {
        setSelectedValue(value);
        callback(value);
    }

    return (
        <>
        <div className="customButtonRadioArea" style={{ 
        display:'flex', 
        flexDirection:'row',
        justifyContent:'flex-start',
        boxSizing:'border-box' }} >
            {label.map((item, index) => (
                <button key={index} 
                onClick={()=>{handleChange(item.value)}}
                style={{
                    width:width, 
                    backgroundColor:backgroundColor,
                    border: selectedValue === item.value ? '1px solid #333333' : errorTextIdx? "1px solid red" : border, 
                    color: selectedValue === item.value ? '#333333' : fontColor, 
                    padding: padding, 
                    cursor:'pointer',
                    fontSize: '14px',
                    fontWeight: 400,
                    ...(index == 0 ? { borderRadius:'4px 0 0 4px', } : {}),
                    ...(index === label.length - 1 ? {  borderRadius:'0 4px 4px 0' } : {}),
                }}>{item.name}</button>
            ))}
        </div>
        {errorText && <p style={{color:'red', fontSize:'12px', marginTop:'8px'}}>{errorText}</p>}
        </>
    );
}

export default CustomButtonRadio;
