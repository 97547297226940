import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();
const API_SERVER = process.env.REACT_APP_API_URLS;

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRoles, setUserRoles] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [churchId, setChurchId] = useState(1);
    const [communityId, setCommunityId] = useState(null);
    const [loginId, setLoginId] = useState(null);
    const navigate = useNavigate();


    const login = (roles, accessToken, refreshToken, communityId, loginId) => {
        setIsAuthenticated(true);
        setUserRoles(roles.split(',').map(role => role.trim()));
        setAccessToken(accessToken);
        setCommunityId(communityId);
        setLoginId(loginId);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('roles', roles);
        localStorage.setItem('communityId', communityId);
        localStorage.setItem('loginId', loginId);
        axios.defaults.headers.common['authorization'] = `Bearer ${accessToken}`;
    };

    const logout = (callback) => {
        setIsAuthenticated(false);
        setAccessToken(null);
        setUserRoles(null);
        setCommunityId(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('roles');
        localStorage.removeItem('communityId');
        localStorage.removeItem('loginId');
        delete axios.defaults.headers.common['authorization'];
        if (callback) callback(); // 로그아웃 후 콜백 호출
    };

    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const authToken = localStorage.getItem('accessToken');
            // console.log("refreshAccessToken", refreshToken, authToken);
            if (refreshToken) {
                const response = await axios.post(API_SERVER+'/AUTH/REFRESH', {
                    accessToken: authToken,
                    refreshToken: refreshToken
                });
                if (response.status === 200) {
                    const responseData = response.data;
                    setAccessToken(responseData.accessToken);
                    localStorage.setItem('accessToken', responseData.accessToken);
                    localStorage.setItem('refreshToken', responseData.refreshToken);
                    localStorage.setItem('roles', responseData.role);
                    axios.defaults.headers.common['authorization'] = `Bearer ${responseData.accessToken}`;
                    return true;
                }
            }
        } catch (error) {
            console.error('Failed to refresh access token', error);
        }
        return false;
    };

    const checkAuth = () => {
        const storedAccessToken = localStorage.getItem('accessToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');
        const roles = localStorage.getItem('roles');
        const communityId = localStorage.getItem('communityId');
        if (storedAccessToken && storedRefreshToken && roles && communityId) {
            setCommunityId(communityId);
            setUserRoles(roles.split(',').map(role => role.trim()));
            axios.defaults.headers.common['authorization'] = `Bearer ${storedAccessToken}`;

            setIsAuthenticated(true);
            return true;
        }
        else{
            setIsAuthenticated(false);
            logout( () => { navigate('/login') } );
        }
    }

    useEffect(() => {
        const initializeAuth = async () => {
            const storedAccessToken = localStorage.getItem('accessToken');
            const storedRefreshToken = localStorage.getItem('refreshToken');
            const roles = localStorage.getItem('roles');
            const communityId = localStorage.getItem('communityId');
            if (storedAccessToken && storedRefreshToken && roles && communityId) {
                setCommunityId(communityId);
                setUserRoles(roles.split(',').map(role => role.trim()));
                axios.defaults.headers.common['authorization'] = `Bearer ${storedAccessToken}`;

                setIsAuthenticated(true);
            }
            else{
                setIsAuthenticated(false);
                logout( () => { navigate('/login') } );
            }
        };
        initializeAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, checkAuth, userRoles, communityId, churchId, login, logout, refreshAccessToken, loginId }}>
            {children}
        </AuthContext.Provider>
    );
};