const COLORS = {
    primary: {
        primary: "#F2F2F0",
        primaryBlack: "#1E1F23",
        primaryBlue1: "#83A7FB",
        primaryBlue2: "#BBD1FA",
        primaryBlue3: "#D6E3FC",
    },
    secondary: {

    },
    white: "#FFFFFF",
    black: "#000000",
    black3: "#333333",
    black6: "#666666",
    black9: "#999999",
    grayD: "#DDDDDD",
    grayED: "#EDEDED",
}

export default COLORS;