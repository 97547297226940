// Favicon
import FAVICON from '../res/favicon.ico'

// Icon
import ARROW_DOWN_G from '../res/icon/Icon-arrow-down-g.svg'
import ARROW_UP_G from '../res/icon/Icon-arrow-up-g.svg'
import ARROW_UP_B from '../res/icon/Icon-arrow-up-b.svg'
import CALENDAR_CHECK_MONO from '../res/icon/icon-calendar-check-mono.svg'
import LIST_DOT_ACTIVATE from '../res/icon/icon-list-dot-activate.svg'
import LIST_DOT_DEACTIVATE from '../res/icon/icon-list-dot-deactivate.svg'
import SEARCH_MONO from '../res/icon/icon-search-mono.svg'
import X_MONO from '../res/icon/icon-x-mono.svg'


// Image
import LOGO_B from '../res/image/Image-Logo-b.svg'
import LOGO_W from '../res/image/Image-Logo-w.svg'




const ICONS = {
    ARROW_DOWN_G,
    ARROW_UP_G,
    ARROW_UP_B,
    CALENDAR_CHECK_MONO,
    LIST_DOT_ACTIVATE,
    LIST_DOT_DEACTIVATE,
    SEARCH_MONO,
    X_MONO,
}


const IMAGES = {
    LOGO_B,
    LOGO_W,
}





export { FAVICON, IMAGES, ICONS };