// src/components/CustomImageUpload/CustomImageUpload.js
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import './CustomImageUpload.css'

function CustomImageMultiUpload({ callback, id, imagesUrl, imageFile, width, height, backgroundColor, padding, border, errorText, maxImages }) {
    const [imageUrls, setImageUrls] = useState(imagesUrl || []);
    const [imageFiles, setImageFiles] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const wrapWidth = width * (maxImages + 1);
    const wrapMaxWidth = (width + 20) * 5;

    useEffect(() => {
        setImageUrls(imagesUrl || []);
        const imageFilesTemp = imagesUrl.map((_, i) => imageFile ? imageFile[i] : null);
        setImageFiles(imageFilesTemp);
    }, [imagesUrl]);

    const handleSetImage = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type.toLowerCase();
            if (fileType !== "image/png" && fileType !== "image/jpg" && fileType !== "image/jpeg") {
                setUploadError("PNG 또는 JPG/JPEG 파일을 선택해주세요.");
                return;
            } else {
                setUploadError('');
            }
            
            const reader = new FileReader();
            reader.onload = function(e) {
                const updatedUrls = [...imageUrls];
                const updatedFiles = [...imageFiles];
                if (index >= 0) {
                    updatedUrls[index] = e.target.result;
                    updatedFiles[index] = file;
                } else if (imageUrls.length < maxImages) {
                    updatedUrls.push(e.target.result);
                    updatedFiles.push(file);
                }
                setImageFiles(updatedFiles);
                callback(updatedFiles, updatedUrls);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteImage = (index) => {
        const updatedUrls = [...imageUrls];
        const updatedFiles = [...imageFiles];
        updatedUrls.splice(index, 1);
        updatedFiles.splice(index, 1);
        callback(updatedFiles, updatedUrls);
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const reorderedUrls = Array.from(imageUrls);
        const reorderedFiles = Array.from(imageFiles);

        const [movedUrl] = reorderedUrls.splice(source.index, 1);
        reorderedUrls.splice(destination.index, 0, movedUrl);

        const [movedFile] = reorderedFiles.splice(source.index, 1);
        reorderedFiles.splice(destination.index, 0, movedFile);

        setImageUrls(reorderedUrls);
        setImageFiles(reorderedFiles);
        callback(reorderedFiles, reorderedUrls);
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="imageUpload" direction="horizontal">
                    {(provided) => (
                        <div
                            className="ImageDragContainer"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                width:wrapWidth+'px', maxWidth:wrapMaxWidth+'px', display:'flex', flexDirection:'row', flexWrap: 'wrap'
                            }}
                        >
                            {imageUrls.map((url, index) => (
                                <Draggable key={index} draggableId={`${id}-${index}`} index={index}>
                                    {(provided) => (
                                        <span
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                marginTop: index >= 5 ? '16px' : '0',
                                                width: width + 'px',
                                                height: height + 'px',
                                                marginRight: '16px',
                                                backgroundColor: backgroundColor,
                                                padding: padding,
                                                border: errorText ? "1px solid red" : border,
                                                ...provided.draggableProps.style
                                            }}
                                        >
                                            <input type="file" onChange={(e) => handleSetImage(e, index)} style={{ display: 'none' }} id={`${id}-${index}`} disabled={Boolean(url)} />
                                            <label htmlFor={`${id}-${index}`} >
                                                <span className="CustomButtonContainer" style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                    <span
                                                        className="imageIconWrap"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleDeleteImage(index);
                                                        }}
                                                        style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer'}}
                                                    >
                                                        <FontAwesomeIcon className="imageIcon" icon={faCircleXmark} />
                                                    </span>
                                                    <img src={url} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }} alt="Uploaded" />
                                                </span>
                                            </label>
                                        </span>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            {imageUrls.length < maxImages && (
                                <span style={{ marginTop: imageUrls.length >= 5 ? '16px' : '0' }}>
                                    <input type="file" onChange={handleSetImage} style={{ display: 'none' }} id={id} />
                                    <label htmlFor={id} style={{ cursor: 'pointer' }}>
                                        <span className="CustomButtonContainer"
                                            style={{
                                                width: width,
                                                height: height,
                                                backgroundColor: backgroundColor,
                                                padding: padding,
                                                border: errorText ? "1px solid red" : border,
                                            }}>
                                            <span>+추가</span>
                                        </span>
                                    </label>
                                </span>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {uploadError && <p style={{ color: 'red', fontSize: '12px', marginTop: '8px' }}>{uploadError}</p>}
            {errorText && <p style={{ color: 'red', fontSize: '12px', marginTop: '8px' }}>{errorText}</p>}
        </>
    );
}

export default CustomImageMultiUpload;
