import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

// constants
import { COLORS } from '../../../constants'

// contexts
import { useAuth } from '../../../contexts/AuthContext';

// Components
import { CustomTitle, CustomTable, HorizenLine, LoadingSpinner, Pagination } from "../../../components"

import './BulletinListPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

function BulletinListPage(){

    const { isAuthenticated, accessToken, logout, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState(false);

    // Page
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [contentsCount, setContentsCount] = useState(0);
    const [bulletinList,setBulletinList] = useState([]);

    const columns = useMemo(() => [
        { 
            accessor: "publishDate", 
            Header: "주보 발행일", 
            Cell: ({ value }) => {
                const publishDate = new Date(value); // 발행일 계산
                const today = new Date();
                const isUpcoming = publishDate > today;
        
                // 조건에 따라 스타일 정의
                const dynamicStyle = {
                    width: '135px',
                    color: isUpcoming ? 'blue' : 'black', // 예: 다가오는 일정은 파란색, 발행된 일정은 검정색
                    fontWeight: isUpcoming ? 'bold' : 'normal'
                };
        
                return (
                    <div className="tdBox" style={dynamicStyle}>
                        <p>{formatDate(value)}</p>
                        <p>{isUpcoming&&"발행대기"}</p>
                    </div>
                );
            }
        },
        { accessor: "bulletinDate", Header: "주보 날짜시간", Cell: ({ value }) => <div className="tdBox" style={{ width: '135px' }}>{formatDate(value)}</div>, },
        { accessor: "thumbnailImageUrl", Header: "주보 커버 이미지", Cell: ({ value }) => {
                const isImage = stringToArray(value).length>0
                return (isImage&&<div className="tdBox" style={{ width: '124px', height:'100%'  }}><img src={stringToArray(value)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></div>)
            }
        },
        { accessor: "title", Header: "주보 제목", Cell: ({ value }) => <div className="tdBox" style={{ width: '132px',display:'-webkit-box',WebkitLineClamp:3,WebkitBoxOrient:'vertical' }}>{value}</div>, },
        { accessor: "description", Header: "주보 설명", Cell: ({ value }) => <div className="tdBox" style={{ width: '351px',display:'-webkit-box',WebkitLineClamp:3,WebkitBoxOrient:'vertical' }}>{value}</div>, },
        { accessor: "updatedAt", Header: "최근 수정한 날짜", Cell: ({ value }) => <div className="tdBox" style={{ width: '145px' }}>{formatDatetime(value)}</div>, },
        { accessor: "updatedBy", Header: "최근 수정 유저", Cell: ({ value }) => <div className="tdBox" style={{ width: '200px' }}>{value}</div>, },
    ],[bulletinList]);
    

    useEffect(() => {
        if(isAuthenticated) get_bulletin();
    }, [isAuthenticated, accessToken]);

    useEffect(() => {
        if(isAuthenticated) get_bulletin();
    }, [page]);

    const add_bulletin = () => {
        navigate("/bulletin/bulletin/editor")
    }

    const handleEdit = (selectedRowId) => {
        // console.log("handleEdit",selectedRowId);
        navigate("/bulletin/bulletin/editor/"+selectedRowId)
    };
    const handleDelete = (selectedRowId) => {
        // console.log("handleDelete",selectedRowId);
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
        delete_bulletin(selectedRowId);
    };
    const handlePageChange = (page) => {
        setPage(page);  // 새로운 페이지로 상태 업데이트
    };






    /** ************************************************************
     * Bulletin functions
     ************************************************************ */
    const get_bulletin = async () => {
        try {
            const response = await axios.get(`${API_SERVER}/bulletins`, {
                params: { page, limit }
            });
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log("[get_bulletin]","[GET]",API_SERVER+'/bulletins',responseData);

                if(responseData.list) setBulletinList(responseData.list);
                if(responseData.listCount) setContentsCount(responseData.listCount);
                setInitData(true)
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.error("error",error);
            if (error.response) {
                console.error("error.response",error.response);
            } else if (error.request) {
                console.error('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.error('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        } finally {
            setLoading(false);
        }
    }
    const delete_bulletin = async (bulletinId) => {
        setLoading(true);
        try {
            const response = await axios.delete(API_SERVER+'/bulletins/'+bulletinId);
            if (response.status === 200) {
                await get_bulletin();
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.error("error",error);
            if (error.response) {
                console.error("error.response",error.response);
            } else if (error.request) {
                console.error('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.error('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        }
    }

    const formatDate = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hour = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const minute = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();
        const second = today.getSeconds()>9? today.getSeconds() : "0"+today.getSeconds();

        return `${year}.${month}.${day} ${hour}:${minute}`;
    }
    const formatDatetime = (date) => {
        if(!date) return "-";
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hour = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const minute = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();
        const second = today.getSeconds()>9? today.getSeconds() : "0"+today.getSeconds();
        return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
    }
    const stringToArray = (str) => {
        if (typeof str !== "string") return [];

        // 빈 배열 형태의 문자열 처리
        if (str.trim() === "[]") return [];

        // 정상적인 URL,URL,URL 형태의 문자열을 배열로 변환
        return str.split(",").map((item) => item.trim());
    }

    if(!initData) return;

    return (
        <div className="contentsPage bulletinListWrap">
            {loading&& <LoadingSpinner/>}
            
            {/* Title Container */}
            <CustomTitle 
                title="주보" 
                titleSize="24px"
                titleWeight="500"

                buttonTitle="주보 생성" 
                buttonOnClick={add_bulletin}
                buttonBackgroundColor={COLORS.white} 
                buttonFontColor={COLORS.black3} 
                buttonPadding="8px 16px"
                buttonBorder={"1px solid "+ COLORS.black3} />
            <HorizenLine color="#999999" />
            
            {/* Pagination Section */}
            <div className="tableContainer">
                <Pagination 
                totalContents={contentsCount}
                contentsLimit={limit}
                currentPage={page}
                callback={handlePageChange}/>
            </div>

            {/* List Section */}
            <div className="tableContainer">
                <CustomTable
                    data={bulletinList}
                    columns={columns}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    listDot={true}
                    idField={"bulletinId"}
                    userRole={userRoles}
                    roleType={roleType} />
            </div>
        </div>
    );
}
export default BulletinListPage;