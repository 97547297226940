// src/components/HorizenLine.js
import React from 'react';

function HorizenLine({ color }) {
    return (
        <div style={{width:"100%", height:"1px", backgroundColor:color}}></div>
    );
}

export default HorizenLine;
