import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';


import { useAuth, AuthProvider } from './contexts/AuthContext';
import { FAVICON } from './res';


import Login from './pages/Login/LoginPage';
import Screen from './components/Screen/Screen';
import SettingsAdminInvite from './pages/Settings/Admin/SettingsAdminInvitePage';


import './index.css'
import './assets/font/pretendard/pretendard.css'



const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <Router>
      <AuthProvider>
        <Helmet>
          <title>I Will Admin</title>
          <link rel="icon" href={FAVICON} />
        </Helmet>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/worship/list" element={<Screen />} />
          <Route path="/worship/editor/:id?" element={<Screen />} />


          <Route path="/bulletin/bulletin/list" element={<Screen />} />
          <Route path="/bulletin/bulletin/editor/:id?" element={<Screen />} />

          <Route path="/bulletin/bulletinContents/list" element={<Screen />} />
          <Route path="/bulletin/bulletinContents/editor/:id?" element={<Screen />} />

          <Route path="/ads/ads/list" element={<Screen />} />
          <Route path="/ads/ads/editor/:id?" element={<Screen />} />
          <Route path="/ads/banner/list" element={<Screen />} />

          <Route path="/push" element={<Screen />} />

          <Route path="/settings/admin/list" element={<Screen />} />
          <Route path="/settings/admin/invite" element={<SettingsAdminInvite />} />


        </Routes>
      </AuthProvider>
    </Router>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
