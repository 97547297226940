// src/components/CustomButtonRadio/CustomButtonRadio.js
import React, { useEffect, useState } from 'react';

import './CustomCheckbox.css'


function CustomCheckbox({ callback, label, data, width, backgroundColor, border, fontColor, padding, errorText }) {

    const [selectedValue, setSelectedValue] = useState(false);
    const [errorTextIdx, setErrorTextIdx] = useState();

    useEffect(()=>{
        if(data) setSelectedValue(data)
    },[data]);

    useEffect(()=>{
        if(errorText==null) setErrorTextIdx(false)
        else setErrorTextIdx(true)
    },[errorText])


    const handleChange = async (event)=> {
        const isChecked = event.target.checked;
        setSelectedValue(isChecked);
        callback(isChecked);
    }

    return (
        <>
        <div className="customCheckboxArea" style={{ 
        display:'flex', 
        flexDirection:'row',
        justifyContent:'flex-start',
        boxSizing:'border-box' }} >
            <input 
                type="checkbox" 
                checked={selectedValue}
                onChange={handleChange} 
            />
        </div>
        {errorText && <p style={{color:'red', fontSize:'12px', marginTop:'8px'}}>{errorText}</p>}
        </>
    );
}

export default CustomCheckbox;
