import React, { useState  } from "react";

import './LoginPage.css';

// component
import Login from '../../components/Login'

// res
import { IMAGES } from '../../res'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const ENV_LABEL = process.env.REACT_APP_NODE_ENV;
const BUILD_TIME = process.env.REACT_APP_BUILD_TIME || "Unknown build time";


function LoginPage(){

    const [isLogin, setIsLogin] = useState(false);

    // 일반 로그인 처리 함수
    const handleLogin = (event) => {
        event.preventDefault();
    };
  

    return (
        <div className="loginWrap">
            <div className="loginContainer">
                <div className="loginLogoBox">
                    <img className="logo" src={IMAGES.LOGO_B} alt="description" />
                    <span>Admin</span>
                </div>
                <div className="logiConstentsBox">
                    <Login />
                </div>
                {ENV && <div className="envLabel" style={{color:'#ccc'}}>{ENV_LABEL}:{BUILD_TIME}</div>}
            </div>
        </div>
    );
  }
  export default LoginPage;