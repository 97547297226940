// src/components/TopNav.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// constants
import { COLORS } from '../constants'

import { useAuth } from '../contexts/AuthContext';


// res
import { IMAGES, ICONS } from '../res'

function TopNav() {
    let navigate = useNavigate();
    const { logout } = useAuth();
    const { loginId } = useAuth();
    // console.log('loginId',loginId)

    const [account,setAccount] = useState('123@456.com')


    return (
        <div className="topNavWrap" style={{ backgroundColor: COLORS.primary.primaryBlack, color: COLORS.white }}>
            <div className="leftSide">
                <img className="logo" src={IMAGES.LOGO_W} />
            </div>
            <div className="rightSide">
                {/* <span className="alertButtonBox">알림</span> */}
                <span className="accountBox">
                    {loginId}
                    {/* <img className="arrowButton" src={ICONS.ARROW_DOWN_G} /> */}
                </span>
                <span className="alertButtonBox" style={{border:'1px solid #fff',padding:'5px 10px',marginRight:'20px'}} onClick={()=>{logout(()=>{navigate('/login')})}}>로그아웃</span>
            </div>
        </div>
    );
}

export default TopNav;
