// src/components/CustomDateSelect/CustomDateSelect.js
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

// res
import { ICONS } from '../../res'


import './CustomDatetimeSelect.css'


export function CustomDateSelect({ callback, data, width, backgroundColor, border, fontColor, padding, textLength, errorText, styles }) {

    const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
        setSelectedDate(data ? new Date(data) : null);
    }, [data]);

    const handleChange = async (date)=> {
        setSelectedDate(date);
        callback(date.toISOString());
    }

    const dayClassName = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        let className = '';
    
        // 과거 날짜 스타일 추가
        if (date < today) {
            className += 'past-day ';
        }
        // 일요일 스타일 추가
        else if (date.getDay() === 0) {
            className += 'react-datepicker__day--sunday ';
        }
        return className.trim();
    };

    
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <div style={{ flex:1, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <span style={{ display:'inline-block', verticalAlign:'center', fontSize:'14px',...value ? null: {color: "#999999"} }}>{value || 'YYYY.MM.DD'}</span>
            <span style={{ display:'inline-block', verticalAlign:'center'}}>
                <button ref={ref} className="custom-datepicker-input" onClick={onClick}>
                    <img className="calendar-icon" src={ICONS.CALENDAR_CHECK_MONO} alt="Collapse" />
                </button>
            </span>
        </div>
    ));
  

    return (
        <div>
        <div className="customDate" 
        style={{...styles,
        width:width, 
        backgroundColor:backgroundColor, 
        border:errorText? "1px solid red":border, 
        color:fontColor, 
        padding: padding, 
        borderRadius:'4px', 
        display:'flex', 
        flexDirection:'row',
        justifyContent:'space-between',
        boxSizing:'border-box' }} >   
            <DatePicker
            selected={selectedDate}
            onChange={date => handleChange(date)}
            dateFormat="yyyy.MM.dd"
            dropdownMode="select"
            dayClassName={dayClassName} 
            className="customInput" 
            customInput={<CustomInput />}
            />
        </div>
        {errorText && <p style={{color:'red', fontSize:'12px', marginTop:'8px'}}>{errorText}</p>}
        </div>
    );
}


export function CustomHourSelect({ callback, data, width, backgroundColor, border, fontColor, padding, textLength, errorText, styles }) {

    const [selectedHour, setSelectedHour] = useState('');

    useEffect(() => {
        setSelectedHour(data !== undefined && data !== null ? data : '');
    }, [data]);
    

    const handleSelectChange = async (event)=> {
        const selectedValue = event.target.value;
        setSelectedHour(selectedValue);
        callback(selectedValue);
    }
    
    if(selectedHour === null){
        return null;
    }
  

    return (
        <div>
            <div className="customHour"
            style={{...styles,
                width:width, 
                backgroundColor:backgroundColor, 
                border:errorText? "1px solid red":border, 
                color:fontColor, 
                padding: padding, 
                borderRadius:'4px', 
                display:'flex', 
                flexDirection:'row',
                justifyContent:'space-between',
                boxSizing:'border-box'}}>
                <select
                    onChange={handleSelectChange}
                    value={selectedHour}
                    style={{
                        width: width,
                        backgroundColor: backgroundColor,
                        color: selectedHour !== '' ? fontColor : '#999999',
                        justifyContent: 'space-between',
                        boxSizing: 'border-box'
                    }} >
                    <option className="selectPlaceholder" value="" disabled>HH</option>
                    {/* 시간 옵션들을 자동으로 생성 */}
                    {Array.from({ length: 24 }, (_, i) => (
                        <option value={i} key={i}>{i.toString().padStart(2, '0')}</option>
                    ))}
                </select>
            </div>
            {errorText && <p style={{ color: 'red', fontSize: '12px', marginTop: '8px' }}>{errorText}</p>}
        </div>
    );
}


export function CustomMinSelect({ callback, data, width, backgroundColor, border, fontColor, padding, textLength, errorText, styles }) {

    const [selectedMin, setSelectedMin] = useState('');

    useEffect(() => {
        setSelectedMin(data !== undefined && data !== null ? data : '');
    }, [data]);

    const handleSelectChange = async (event) => {
        const selectedValue = event.target.value;
        setSelectedMin(selectedValue);
        callback(selectedValue);
    }
  

    return (
        <div>
            <div className="customMin"
            style={{...styles,
                width:width, 
                backgroundColor:backgroundColor, 
                border:errorText? "1px solid red":border, 
                color:fontColor, 
                padding: padding, 
                borderRadius:'4px', 
                display:'flex', 
                flexDirection:'row',
                justifyContent:'space-between',
                boxSizing:'border-box'}}>
            <select className="customMin" 
            onChange={handleSelectChange}
            value={selectedMin}
            style={{
                width: width,
                backgroundColor: backgroundColor,
                color: selectedMin !== '' ? fontColor : '#999999',
                justifyContent: 'space-between',
                boxSizing: 'border-box'
            }} >
                <option className="selectPlaceholder" value="" disabled>MM</option>
                {/* 분 옵션들을 5분 단위로 자동 생성 */}
                {Array.from({ length: 12 }, (_, i) => (
                    <option value={i * 5} key={i}>{(i * 5).toString().padStart(2, '0')}</option>
                ))}         
            </select>
        </div>
        {errorText && <p style={{color:'red', fontSize:'12px', marginTop:'8px'}}>{errorText}</p>}
        </div>
    );
}
// export default { CustomDateSelect, CustomHourSelect };
