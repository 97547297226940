// src/components/CustomButton/CustomButton.js
import React from 'react';

import './CustomButton.css'


function CustomButton({ title, onClick, width, backgroundColor, fontColor, padding, border }) {
    return (
        <button className="CustomButtonContainer" onClick={onClick} style={{ width:width, backgroundColor:backgroundColor, color:fontColor, padding: padding, border: border, borderRadius:'4px', cursor:'pointer' }}>
                {title}
        </button>
    );
}

export default CustomButton;
