// src/components/Screen.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import './Screen.css'

// components
import TopNav from '../TopNav';
import SideMenu from '../SideMenu';

// screen
import WorshipList from '../../pages/Worship/WorshipListPage';
import WorshipEditor from '../../pages/Worship/WorshipEditorPage';


import BulletinList from '../../pages/Bulletin/Bulletin/BulletinListPage';
import BulletinEditor from '../../pages/Bulletin/Bulletin/BulletinEditorPage';

import BulletinContentsList from '../../pages/Bulletin/BulletinContents/BulletinContentsListPage';
import BulletinContentsEditor from '../../pages/Bulletin/BulletinContents/BulletinContentsEditorPage';

import AdsList from '../../pages/Ads/Ads/AdsListPage';
import AdsEditor from '../../pages/Ads/Ads/AdsEditorPage';
import BannerList from '../../pages/Ads/Banner/BannerListPage';

import PushPage from '../../pages/Push/PushPage';

import SettingsAdmin from '../../pages/Settings/Admin/SettingsAdminPage';

function Screen() {
    const location = useLocation();
    let navigate = useNavigate();
    const { id } = useParams(); // URL 파라미터에서 id를 가져옴
    // console.log(location.pathname);

    // useEffect(() => {
    //     console.log("reload");
    // },[]);


    // URL 경로와 해당하는 컴포넌트를 매핑
    const pageRoutes = {
        "/worship/list": <WorshipList />,
        "/worship/editor": <WorshipEditor id={id}/>,

        "/bulletin/bulletin/list": <BulletinList />,
        "/bulletin/bulletin/editor": <BulletinEditor id={id}/>,     

        "/bulletin/bulletinContents/list": <BulletinContentsList />,   
        "/bulletin/bulletinContents/editor": <BulletinContentsEditor id={id}/>,  

        "/ads/ads/list": <AdsList />,   
        "/ads/ads/editor": <AdsEditor id={id}/>, 
        "/ads/banner/list": <BannerList />,   

        "/push": <PushPage />,

        "/settings/admin/list": <SettingsAdmin />,
    };


    // 현재 경로에 맞는 페이지 컴포넌트를 찾아서 반환
    const getPageComponent = (pathname) => {
        if (pathname.startsWith("/worship/editor/")) {
            return <WorshipEditor id={id} />;
        }
        else if (pathname.startsWith("/bulletin/bulletin/editor/")) {
            return <BulletinEditor id={id} />;
        }
        else if (pathname.startsWith("/bulletin/bulletinContents/editor/")) {
            return <BulletinContentsEditor id={id} />;
        }
        else if (pathname.startsWith("/ads/ads/editor/")) {
            return <AdsEditor id={id} />;
        }
        return pageRoutes[pathname] || <div>페이지를 찾을 수 없습니다.</div>;
    };


    return (
        <div className="screenContainer">
            <TopNav/>
            <div className="contentSection">
                <SideMenu />
                {getPageComponent(location.pathname)}
            </div>
        </div>
    );
}

export default Screen;
