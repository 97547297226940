// src/components/Login.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';


// res
import { IMAGES } from '../res'

const API_SERVER = process.env.REACT_APP_API_URLS;

function Login() {
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [errorText, setErrorText] = useState(' ');

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            navigate('/worship/list');
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(API_SERVER+'/auth/login', {
                loginId: email,
                loginPassword: password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
                
            if (response.status === 200) {
                const responseData = response.data.data;
                login(responseData.user.role, responseData.token.accessToken,responseData.token.refreshToken,responseData.user.communityId,responseData.user.loginId);
                // localStorage.setItem('accessToken', responseData.token.accessToken);
                // localStorage.setItem('refreshToken', responseData.token.refreshToken);
		        // axios.defaults.headers.common['Authorization'] = `Bearer ${responseData.token.accessToken}`;
                const roles = responseData.user.role.split(',').map(role => role.trim());
                const bulletinRoles = ['bulletin','bulletin_viewer','bulletin_editor'];

                if(roles.includes('admin')) {
                    navigate('/worship/list');
                }
                else if(bulletinRoles.includes(roles[0])) {
                    navigate('/worship/list');
                }
                else if(roles.includes('push')) {
                    navigate('/push');
                }

            } else {
                console.log("failed login");
                setErrorText('아이디 또는 비밀번호가 일치하지 않습니다.');
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('로그인 실패: 아이디 또는 비밀번호가 일치하지 않습니다.');
                    setErrorText('아이디 또는 비밀번호가 일치하지 않습니다.');
                }
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                setErrorText('서버로부터 응답이 없습니다.');
            } else {
                console.log('로그인 실패: ' + error.message);
            }
        }
    };

    return (
        <form onSubmit={handleLogin}>
            <div className="loginInputBox">
                <p className="loginInputWrap">
                    <input
                        className="loginInput"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </p>
                <p className="loginInputWrap">
                    <input
                        className="loginInput"
                        type={showPassword ? "text" : "password"} 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <FontAwesomeIcon
                        className="showHide"  
                        icon={faEye}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{color: showPassword? '#333': '#ccc'}}
                    />
                </p>
                <p className="errorText">{errorText}</p>
            </div>
            <div className="loginButtonBox">
                <button className="loginButton" type="submit">로그인</button>
            </div>
            
            <div className="passwordResetBox">
                <p className="passwordResetText">비밀번호 재설정</p>
            </div>
        </form>
    );
}

export default Login;
