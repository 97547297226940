// src/components/Pagination/Pagination.js
import React from 'react';

// components
import { CustomButton } from ".."

import './Pagination.css'


function Pagination({ totalContents, contentsLimit, currentPage, callback }) {
    const totalPages = Math.ceil(totalContents / contentsLimit);
    const maxPageButtons = 10; // 최대 페이지 버튼 수

    // 페이지 버튼 배열 생성 함수
    const getPageNumbers = () => {
        const pageNumbers = [];
        const half = Math.floor(maxPageButtons / 2);

        let startPage = Math.max(0, currentPage - half);
        let endPage = Math.min(totalPages - 1, currentPage + half);

        if (endPage - startPage < maxPageButtons - 1) {
            if (startPage === 0) {
                endPage = Math.min(totalPages - 1, startPage + maxPageButtons - 1);
            } else if (endPage === totalPages - 1) {
                startPage = Math.max(0, endPage - maxPageButtons + 1);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (startPage > 0) {
            pageNumbers.unshift(0, '...');
        }
        if (endPage < totalPages - 1) {
            pageNumbers.push('...', totalPages - 1);
        }

        return pageNumbers;
    };

    const handlePageChange = (page) => {
        if (page !== '...' && page !== currentPage) {
            callback(page);
        }
    };

    // 페이지네이션이 필요한 경우에만 렌더링
    return totalPages > 1 ? (
        <div className="PaginationContainer">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
                {'<'}
            </button>

            {getPageNumbers().map((page, index) => (
                <button
                    key={index}
                    onClick={() => handlePageChange(page)}
                    disabled={page === currentPage || page === '...'}
                    className={page === currentPage ? 'active' : ''}
                >
                    {typeof page === 'number' ? page + 1 : page}
                </button>
            ))}

            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1}>
                {'>'}
            </button>
        </div>
    ) : 
    <div className="PaginationContainer"></div>;
}

export default Pagination;
