// src/components/LoadingSpinner.js
import React from 'react';
import { SyncLoader } from "react-spinners";

function LoadingSpinner({ color }) {
    return (
        <div style={{position:'fixed', zIndex:10, top:0, left:0, backgroundColor:'rgba(0,0,0,0.3)',width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <SyncLoader />
        </div>
    );
}

export default LoadingSpinner;
