import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTable, useRowSelect, usePagination } from 'react-table';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// constants
import { COLORS } from '../../../constants'

// Components
import { CustomContentsTitle, CustomInputBox, CustomSelectBox, CustomButton, HorizenLine } from "../../../components"

// res
import { ICONS } from "../../../res"


import './SettingsAdminPage.css'


function SettingsAdminInvitePage(){

    let navigate = useNavigate();
    const [validation,setValidation] = useState(false);
    
    const handleClose = () => {
        
    }
    const sampleRole = ['소유자','편집자','보기 전용'];

    return (
        <div className="settingsAdminInviteWrap">
            <div className="inviteBox">
                <div className="inviteTitle">
                    <span></span>
                    <span className="title">멤버초대</span>
                    <span className="closeBtn" onClick={()=>{navigate(-1)}}><img src={ICONS.X_MONO}/></span>
                </div>
                <div style={{padding:'16px 0px',}}>
                    <div className="inviteContents">
                        <div>
                            <CustomContentsTitle 
                            title={"초대할 멤버의 이메일 주소를 입력해주세요"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}/>
                            <div className="inviteContentsInput">
                                <CustomInputBox 
                                callback={()=>{}}
                                placeholder={"이메일 주소 입력"}
                                width={"340px"}
                                border={"1px solid "+ COLORS.grayD}
                                fontColor={COLORS.black3}
                                padding={"8px"}
                                textLength={100}
                                styles={{marginRight:'8px'}}/>
                                <CustomSelectBox 
                                list={sampleRole}
                                defaultList={'역할 선택'}
                                width={'118px'}
                                callback={()=>{}}
                                padding={'8px'}
                                border={'1px solid #DDDDDD'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="inviteButton">
                        <div className="contentsButtonBox">
                            <CustomButton 
                            title={"취소"}
                            onClick={() => {navigate(-1)}}
                            width={"auto"}
                            padding={"8px 16px"}
                            backgroundColor={COLORS.white}
                            border={"1px solid"+COLORS.black3} />
                            <CustomButton 
                            title={"초대"}
                            onClick={()=>{}}
                            width={"auto"}
                            padding={"8px 16px"}
                            fontColor={validation? COLORS.white:COLORS.black9}
                            backgroundColor={validation? COLORS.black3:COLORS.grayED}
                            border={"1px solid"+validation? COLORS.black3:COLORS.grayED}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SettingsAdminInvitePage;


