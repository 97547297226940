import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

// constants
import { COLORS } from '../../constants'

// contexts
import { useAuth } from '../../contexts/AuthContext';

// Components
import { CustomSelectBox, CustomTitle, CustomTable, HorizenLine, LoadingSpinner, Pagination } from "../../components"


import './WorshipListPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

function WorshipListPage(){

    const { isAuthenticated, accessToken, logout, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState(false);

    // Page
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [contentsCount, setContentsCount] = useState(0);

    const filterDefault = "전체";
    const filterCategory = ["주일 예배","주중 예배"];
    const [selectedCategory, setSelectedCategory] = useState(); // Table Filer를 위한 값
    const [selectedCategoryName, setSelectedCategoryName] = useState(filterDefault); // Filter SelectBox를 위한 값
    const [worshipList,setWorshipList] = useState([]);

    
    const defaultFilter = (rows, id, filterValue) => {
        return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue !== undefined
                ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                : true;
        });
    };

    const columns = useMemo(() => [
        { accessor: "title", Header: "제목", Cell: ({ value }) => <div className="tdBox" style={{ width: '250px' }}>{value}</div>, },
        { accessor: "description", Header: "설명", Cell: ({ value }) => <div className="tdBox" style={{ width:'300px',display:'-webkit-box',WebkitLineClamp:3,WebkitBoxOrient:'vertical' }}><pre>{value}</pre></div>, },
        { accessor: "scripture", Header: "말씀 본문", Cell: ({ value }) => <div className="tdBox" style={{ width: '114px' }}>{value}</div>, },
        { accessor: "speaker", Header: "설교자", Cell: ({ value }) => <div className="tdBox" style={{ width: '92px' }}>{value}</div>, },
        { accessor: "worshipDate", Header: "예배시간", Cell: ({ value }) => <div className="tdBox" style={{ width: '120px' }}>{formatDate(value)}</div>, },
        { accessor: "sundayWorship", Header: "주일예배 여부", Cell: ({ value }) => <div className="tdBox" style={{ width: '109px' }}>{value==false? "주중 예배":"주일 예배"}</div>, Filter: selectedCategory, filter: defaultFilter, },
        { accessor: "updatedAt", Header: "최근 수정한 날짜", Cell: ({ value }) => <div className="tdBox" style={{ width: '145px' }}>{formatDatetime(value)}</div>, },
        { accessor: "updatedBy", Header: "최근 수정 유저", Cell: ({ value }) => <div className="tdBox" style={{ width: '200px' }}>{value}</div>, },
    ],[worshipList]);

    /** ************************************************************
     * Get worship data
     ************************************************************ */
    useEffect(() => {
        if(isAuthenticated) get_worships();
    }, [isAuthenticated, accessToken]);

    useEffect(() => {
        if(isAuthenticated) get_worships();
    }, [page]);
    

    const handleEdit = (selectedRowId) => {
        navigate("/worship/editor/"+selectedRowId)
    };
    const handleDelete = (selectedRowId) => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
        delete_worship(selectedRowId);
    };
    const handlePageChange = (page) => {
        setSelectedCategory(-1);
        setSelectedCategoryName(filterDefault);
        setPage(page);
    };
    useEffect(() => {
        // console.log("selectedCategory",selectedCategory);
    }, [selectedCategory]);

    const add_worship = () => {
        navigate("/worship/editor")
    }

    const categorySelectboxCallback = (data) => {
        setSelectedCategory(data==filterDefault? -1: data=="주일 예배"? 1 :0);
        setSelectedCategoryName(data);
    }

    const get_worships = async () => {
        try {
            const response = await axios.get(`${API_SERVER}/WORSHIPS`, {
                params: { page, limit }
            });
            
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log("[get_worships]","[GET]",API_SERVER+'/WORSHIPS',responseData);
                if(responseData.list) setWorshipList(responseData.list);
                if(responseData.listCount) setContentsCount(responseData.listCount);
                setLoading(false);
                setInitData(true);
            } else {
                console.log("Failed login");
            }
        } catch (error) {
            console.error("error",error);
            if (error.response) {
                console.error("error.response",error.response);
            } else if (error.request) {
                console.error('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.error('로그인 실패: ' + error.message);
            }
            logout(()=>{navigate("/login")});
        } finally {
            setLoading(false);
        }
    }
    const delete_worship = async (worshipId) => {
        setLoading(true);
        try {
            const response = await axios.delete(API_SERVER+'/worships/'+worshipId);
            if (response.status === 200) {
                await get_worships();
            } else {
                console.log("Failed login");
            }
        } catch (error) {
            console.error("error",error);
            if (error.response) {
                console.error("error.response",error.response);
            } else if (error.request) {
                console.error('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.error('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        }
    }

    const formatDate = (date) => {
        if(!date) return "-";
        
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hour = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const minute = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();

        // const [datePart, timePartWithZ] = date.split('T');
        // const timePart = timePartWithZ.replace('Z', '');
        // const [year, month, day] = datePart.split('-');
        // const [hour, minute, second] = timePart.split(':');

        return `${year}.${month}.${day} ${hour}:${minute}`;
    }
    const formatDatetime = (date) => {
        if(!date) return "-";
        
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hour = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const minute = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();
        const second = today.getSeconds()>9? today.getSeconds() : "0"+today.getSeconds();

        // const [datePart, timePartWithZ] = date.split('T');
        // const timePart = timePartWithZ.replace('Z', '');
        // const [year, month, day] = datePart.split('-');
        // const [hour, minute, second] = timePart.split(':');
        return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
    }

    if(!initData) return;

    return (
        <>
        <div className="contentsPage worshipListWrap">
            {loading&& <LoadingSpinner/>}
            
            {/* Title Container */}
            <CustomTitle 
                title="예배 관리" 
                titleSize="24px"
                titleWeight="500"

                buttonTitle="예배 생성" 
                buttonOnClick={add_worship}
                buttonBackgroundColor={COLORS.white} 
                buttonFontColor={COLORS.black3} 
                buttonPadding="8px 16px"
                buttonBorder={"1px solid "+ COLORS.black3} />
            <HorizenLine color="#999999" />
            

            {/* Filter & Search Container */}
            <div className="filterSearchContainer">
                <span>총 {contentsCount}개</span>
                <span>
                    <span style={{ marginRight: "8px" }}>주일예배 여부</span>
                    <CustomSelectBox 
                        defaultList={filterDefault}
                        defaultValue={selectedCategoryName}
                        list={filterCategory}
                        callback={categorySelectboxCallback}
                        border="1px solid #999999"
                        padding="8px" />
                </span>
            </div>

            {/* Pagination Section */}
            <div className="tableContainer">
                <Pagination 
                totalContents={contentsCount}
                contentsLimit={limit}
                currentPage={page}
                callback={handlePageChange}/>
            </div>

            {/* List Section */}
            <div className="tableContainer">
                <CustomTable
                    data={worshipList}
                    columns={columns}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    listDot={true}
                    filterName={"sundayWorship"}
                    filter={selectedCategory}
                    idField={"worshipId"}
                    userRole={userRoles}
                    roleType={roleType} />
            </div>
        </div>
        </>
    );
}
export default WorshipListPage;