const SIDE_MENU = [
    {
        categoryKey: "worship",
        category: "예배",
        url: "/worship/list",
        roles: ["admin","bulletin","bulletin_viewer","bulletin_editor"],
        menu: []
    },
    {
        categoryKey: "bulletin",
        category: "주보",
        url: "",
        roles: ["admin","bulletin","bulletin_viewer","bulletin_editor"],
        menu: [
            {
                name: "주보 관리",
                url: "/bulletin/bulletin/list",
            },
            {
                name: "주보 컨텐츠",
                url: "/bulletin/bulletinContents/list",
            }
        ]
    },
    {
        categoryKey: "ads",
        category: "광고",
        url: "",
        roles: ["admin","bulletin","bulletin_viewer","bulletin_editor"],
        menu: [
            {
                name: "광고 관리",
                url: "/ads/ads/list",
            },
            {
                name: "배너 관리",
                url: "/ads/banner/list",
            },
        ]
    },
    {
        categoryKey: "push",
        category: "푸시 전송",
        url: "/push",
        roles: ["admin","push_viewer","push_editor"],
        menu: []
    },
    {
        categoryKey: "settings",
        category: "설정",
        url: "",
        roles: ["admin"],
        menu: [
            {
                name: "관리자",
                url: "/settings/admin/list",
            }
        ]
    }
]


export { SIDE_MENU };