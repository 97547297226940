import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

// contexts
import { useAuth } from '../../../contexts/AuthContext';

// Components
import { CustomTitle, CustomSelectBox, CustomTable, HorizenLine } from "../../../components"

import './BannerListPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

function BannerListPage(){

    const { isAuthenticated, accessToken, logout, communityId, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [selectedFilter,setSelectedFilter] = useState(0);
    const [initData,setInitData] = useState(false);

    // Page
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const [adsList,setAdsList] = useState(null);
    const [adsListCount,setAdsListCount] = useState(null);
    const [banners,setBanners] = useState([]);
    const [bannersList,setBannerList] = useState([]);

    const [editBannersList,setEditBannersList] = useState([]);
    const [editNewsList,setEditNewsList] = useState([]);
    const [editRecruitList,setEditRecruitList] = useState([]);

    const [isChanged,setIsChanged] = useState(false);

    const [bannerAdsCount, setBannerAdsCount] = useState([]);
    const newsAdsSampleCount = [1,2];
    const recruitAdsSampleCount = [1,2];


    const columns = useMemo(() => [
        { accessor: "title", Header: "제목", Cell: ({ value }) => <div className="tdBox" style={{ width: '250px' }}>{value}</div>, },
        { accessor: "thumbnailImageUrl", Header: "썸네일 이미지", Cell: ({ value }) => {
            const isImage = stringToArray(value).length>0
            return (isImage&&<div className="tdBox" style={{ width: '124px', height:'100%'  }}><img src={stringToArray(value)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></div>)
            }
        },
        { accessor: "publish_date", Header: "발행일", Cell: ({ value }) => <div className="tdBox" style={{ width: '134px' }}>{formatDatetime(value)}</div>, },
        { accessor: "ad_type", Header: "분류", Cell: ({ value }) => <div className="tdBox" style={{ width: '57px' }}>{formatAdType(value)}</div>, },
        { accessor: "ad_publisher_type", Header: "발행처", Cell: ({ value }) => <div className="tdBox" style={{ width: '57px' }}>{formatAdPublisherType(value)}</div>, },
        { accessor: "banner_ads", Header: "캐러셀", Cell: ({ value, row }) => 
            <div className="tdBox" style={{ width: '77px' }}>
                <CustomSelectBox 
                    list={bannerAdsCount}
                    defaultList={'-'}
                    defaultValue={value}
                    width={'50px'}
                    callback={(value) => handleSelectChange(row.original.id, 'banner_ads', value)}
                    padding={'4px 8px'}
                    border={'1px solid #DDDDDD'}
                    disabled={!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))}/>
        </div>, },
        { accessor: "news_ads", Header: "소식 배너", Cell: ({ value, row }) => 
            <div className="tdBox" style={{ width: '77px' }}>
                {row.original.ad_type==='NEWS'?
                    <CustomSelectBox 
                    list={newsAdsSampleCount}
                    defaultList={'-'}
                    defaultValue={value}
                    width={'50px'}
                    callback={(value) => handleSelectChange(row.original.id, 'news_ads', value)}
                    padding={'4px 8px'}
                    border={'1px solid #DDDDDD'}
                    disabled={!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))}/>: 
                    '-'
                }
        </div>, },
        { accessor: "recruit_ads", Header: "모집 배너", Cell: ({ value, row }) => 
            <div className="tdBox" style={{ width: '77px' }}>
                {row.original.ad_type==='RECRUIT'? 
                    <CustomSelectBox 
                    list={recruitAdsSampleCount}
                    defaultList={'-'}
                    defaultValue={value}
                    width={'50px'}
                    callback={(value) => handleSelectChange(row.original.id, 'recruit_ads', value)}
                    padding={'4px 8px'}
                    border={'1px solid #DDDDDD'}
                    disabled={!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))}/>:
                    '-'
                }
        </div>, },
    ], [bannerAdsCount]);
    
    useEffect(() => {
        if(isAuthenticated) {
            get_banners();
            get_ads();
        }
    }, [isAuthenticated, accessToken]);

    useEffect(() => {
        if(adsList&&banners){
            set_bannerList();
        }
    }, [adsList,banners]);

    useEffect(() => {
        if(isChanged){
            update_ads_home();
        }
    }, [isChanged]);

    useEffect(() => {
        if(adsList){
            let temp = [];
            for(let i=0; i<adsList.length+1; i++){
                temp.push(i+1);
            }
            setBannerAdsCount(temp);
            setInitData(true);
        }
    }, [adsList]);

    
    const handleEdit = (selectedRowId) => {
        // console.log("handleEdit",selectedRowId);
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
    };
    const handleDelete = (selectedRowId) => {
        // console.log("handleDelete",selectedRowId);
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
    };
    const handleSelectChange = (communityAdId, type, value) => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
        // console.log("handleSelectChange", communityAdId, type, value);
        const updateList = (prevList) => {
            let temp = [...prevList]; // 이전 상태 복사

            // 모든 null 값을 앞으로 이동시키기
            temp = temp.filter(item => item !== null).concat(temp.filter(item => item === null));

            const newIndex = value ? value - 1 : -1; // value가 있을 경우 인덱스 설정, 없으면 -1로 설정
            const currentIndex = temp.indexOf(`${communityAdId}`); // 현재 communityAdId의 위치

            if (currentIndex !== -1) {
                if (newIndex !== -1) {
                    // value 값이 있을 때: 현재 위치와 새 위치가 다르면 값을 교환
                    [temp[currentIndex], temp[newIndex]] = [temp[newIndex], temp[currentIndex]];
                } else {
                    // value 값이 없으면 리스트에서 제거
                    temp = temp.filter(item => item !== `${communityAdId}`);
                }
            } else if (newIndex !== -1) {
                // communityAdId가 배열에 없고, value가 있을 경우, 새 위치에 추가
                const existingValueAtNewIndex = temp[newIndex];
                temp[newIndex] = `${communityAdId}`;
                if (existingValueAtNewIndex) {
                    temp[temp.indexOf(existingValueAtNewIndex)] = existingValueAtNewIndex;
                }
            }

            // 다시 모든 null 값을 앞으로 이동시키기
            temp = temp.filter(item => item !== null).concat(temp.filter(item => item === null));

            return temp;
        };

        if (type === 'banner_ads') {
            setEditBannersList(updateList);
        } else if (type === 'news_ads') {
            setEditNewsList(updateList);
        } else if (type === 'recruit_ads') {
            setEditRecruitList(updateList);
        }
        setIsChanged(true); 
    }

    /** ************************************************************
     * Banner functions
     ************************************************************ */
    const get_banners = async () => {
        try {
            const response = await axios.get(`${API_SERVER}/communities/ad-home`);
            if (response.status === 200) {
                const responseData = response.data.data;
                for(let i=0; i<responseData.length; i++){
                    if(responseData[i].communityId==communityId){
                        setBanners(responseData[i]);
                    }
                }
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        } finally {
            setLoading(false);
        }
    }

    /** ************************************************************
     * Ads functions
     ************************************************************ */
    const get_ads = async () => {
        try {
            let index = true;
            let listTemp = [];
            let page = 0;
            while(index){
                const response = await axios.get(API_SERVER+'/communities/ads',{
                    params: { page, limit }
                });
    
                if (response.status === 200) {
                    const responseData = response.data.data;
                    if(ENV) console.log("[get_ads]","[GET]",API_SERVER+'/communities/ads',responseData);
    
                    var AdsListOrigin = responseData.list
                    if (AdsListOrigin.length==0) {
                        index=false;
                        continue;
                    }

                    for(let i=0; i<AdsListOrigin.length; i++){
                        if(AdsListOrigin[i].isShowing==1){
                            listTemp.push(AdsListOrigin[i]);
                        }
                    }
                    page++;
                } else {
                    console.log("failed login");
                }
            }      

            setAdsList(listTemp);
            setAdsListCount(listTemp.length);    

        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        } finally {
            setLoading(false);
        }
    }
    const set_bannerList = () => {
        let bannerListTemp = [];
        let bannersAdsArray = banners.bannersAds? banners.bannersAds.split(','): [];
        let newsAdsArray = banners.newsAds? banners.newsAds.split(','): [];
        let recruitAdsArray = banners.recruitAds? banners.recruitAds.split(','): [];
        setEditBannersList(bannersAdsArray);
        setEditNewsList(newsAdsArray);
        setEditRecruitList(recruitAdsArray);

        for(let i=0; i<adsList.length; i++){
            let bannersAds = 0;
            let newsAds = 0;
            let recruitAds = 0;

            for(let j=0; j<bannersAdsArray.length; j++){
                if(adsList[i].communityAdId==parseInt(bannersAdsArray[j])){
                    bannersAds = `${j+1}`;
                }
            }
            for(let j=0; j<newsAdsArray.length; j++){
                if(adsList[i].communityAdId==parseInt(newsAdsArray[j])){
                    newsAds = `${j+1}`;
                }
            }
            for(let j=0; j<recruitAdsArray.length; j++){
                if(adsList[i].communityAdId==parseInt(recruitAdsArray[j])){
                    recruitAds = `${j+1}`;
                }
            }
            
            const data = {
                "communityAdHomeId":banners.communityAdHomeId,
                "id":adsList[i].communityAdId,
                "title": adsList[i].title,
                "thumbnailImageUrl": adsList[i].thumbnailImageUrl,
                "publish_date":adsList[i].publishDate,
                "ad_type": adsList[i].adType,
                "ad_publisher_type": adsList[i].adPublisherType,
                "banner_ads":bannersAds,
                "news_ads":newsAds,
                "recruit_ads":recruitAds,
            }
            bannerListTemp.push(data);
        }
        setBannerList(bannerListTemp);
    }


    const update_ads_home = async () => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
        try {
            const data = {
                "communityId": communityId,
                "bannersAds": editBannersList.filter(item => item !== null && item !== undefined).join(','),
                "newsAds": editNewsList.filter(item => item !== null && item !== undefined).join(','),
                "recruitAds": editRecruitList.filter(item => item !== null && item !== undefined).join(','),
            }
            // console.log("data",data);
            const response = await axios.put(`${API_SERVER}/communities/ad-home/${banners.communityAdHomeId}`, {"data":data});
            if (response.status === 200) {
                const responseData = response.data.data;
                // console.log(responseData);
                setIsChanged(false);
                setBanners([]);
                setAdsList([]);
                setAdsListCount(0);
                get_ads();
                get_banners();
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        } finally {
            setLoading(false);
        }
    }

    
    const formatAdType = (type) => {
        if(type==='NEWS') return "소식";
        if(type==='RECRUIT') return "모집";
    }
    const formatAdPublisherType = (type) => {
        if(type==='CHURCH') return "교회";
        if(type==='COMMUNITY') return "공동체";
    }
    const formatDatetime = (date) => {
        if(!date) return "-";
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hours = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const minutes = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();
        const seconds = today.getSeconds()>9? today.getSeconds() : "0"+today.getSeconds();
        return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    }
    const stringToArray = (str) => {
        if (typeof str !== "string") return [];

        // 빈 배열 형태의 문자열 처리
        if (str.trim() === "[]") return [];

        // 정상적인 URL,URL,URL 형태의 문자열을 배열로 변환
        return str.split(",").map((item) => item.trim());
    }
    
    if(!initData) return;

    return (
        <div className="contentsPage BannerListWrap">
            {/* Title Container */}
            <CustomTitle 
                title="배너 관리" 
                titleSize="24px"
                titleWeight="500" />
            <HorizenLine color="#999999" />


            {/* Filter & Search Container */}
            <div className="filterSearchContainer">
                {/* 필터 */}
                <span className="countWrap">
                    <span>총 <b>{adsListCount}</b>개</span>
                </span>
            </div>

            {/* List Section */}
            <div className="tableContainer">
                <CustomTable
                    data={bannersList? bannersList:[]}
                    columns={columns}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    listDot={false}
                    userRole={userRoles}
                    roleType={roleType} />
            </div>
        </div>
    );
}
export default BannerListPage;