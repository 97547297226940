// src/components/TopNav.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// contexts
import { useAuth } from '../contexts/AuthContext';

// constants
import { SIDE_MENU } from '../constants/sidemenu'

// res
import { IMAGES, ICONS } from '../res'

function SideMenu() {
    const location = useLocation();
    const segments = location.pathname.split("/");
    const categoryKey = segments[1];
  
    let navigate = useNavigate();
    const { userRoles } = useAuth();

    // 초기 카테고리 열림 상태 설정
    const [openCategories, setOpenCategories] = useState(() => {
        const initialState = {};
        SIDE_MENU.forEach(category => {
            initialState[category.category] = category.categoryKey === categoryKey;
        });
        return initialState;
    });

    // 카테고리 토글 함수
    const toggleCategory = (categoryKey) => {
        setOpenCategories(prevState => ({
            ...prevState,
            [categoryKey]: !prevState[categoryKey]
        }));
    };
    
    function matchFirstTwoSegments(menuItemUrl, currentPath) {
        const urlSegments = menuItemUrl.split('/').filter(Boolean);
        let baseSegment = '';

        if (urlSegments.length >= 2) {
          baseSegment = `/${urlSegments[0]}/${urlSegments[1]}/`;
        } else if (urlSegments.length === 1) {
          baseSegment = `/${urlSegments[0]}/`;
        }
      
        return currentPath.startsWith(baseSegment);
    }

    // Helper function to check if user has access
    const hasAccess = (roles) => {
        // console.log(roles);
        if(userRoles) return roles.some(role => userRoles.includes(role));
    };

    return (
        <div className="sideMenuWrap">
            <p className="sideMenuMainTitle">관리자 센터</p>
            <div className="sideMenuListBox">
            {SIDE_MENU && SIDE_MENU.map((categoryItem, index) => (
            hasAccess(categoryItem.roles || []) && (
                
                    <div className="sideMenuCategoryBox" key={index}>
                        <span 
                        className="sideMenuCategory"
                        style={{
                        color: categoryKey==categoryItem.categoryKey ? '#333333' : '#999999',
                        cursor: 'pointer'
                        }} 
                        onClick={() => { 
                        if(categoryItem.url=="") toggleCategory(categoryItem.category); 
                        else navigate(categoryItem.url) }}>
                            {categoryItem.category}
                            {categoryItem.menu.length==0? 
                                null:
                                openCategories[categoryItem.category] ?  
                                <img className="arrowButton" src={ICONS.ARROW_UP_B} alt="Collapse" /> :
                                <img className="arrowButton" src={ICONS.ARROW_DOWN_G} alt="Expand" />
                            }
                        </span>
                        {openCategories[categoryItem.category] && (
                            <ul>
                            {categoryItem.menu.map((menuItem, menuIndex) => (
                                <li className={matchFirstTwoSegments(menuItem.url,location.pathname) ? "detailMenuList selected" : "detailMenuList"} key={menuIndex} onClick={() => navigate(menuItem.url)}>
                                {menuItem.name}
                                </li>
                            ))}
                            </ul>
                        )}
                    </div>
                )
            ))}
            </div>
        </div>
    );
}

export default SideMenu;
