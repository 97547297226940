import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

// constants
import { COLORS } from '../../../constants'

// contexts
import { useAuth } from '../../../contexts/AuthContext';

// Components
import { CustomInputBox, CustomTitle, CustomDateSelect, CustomTable, HorizenLine, LoadingSpinner, Pagination } from "../../../components"

// res
import { ICONS } from '../../../res'


import './BulletinContentsListPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

function BulletinContentsListPage(){

    const { isAuthenticated, accessToken, logout, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState(false);

    // Page
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [contentsCount, setContentsCount] = useState(0);

    const [itemCount,setItemCount] = useState(0);
    const [mainCategoryFilter,setMainCategoryFilter] = useState("");
    const [subCategoryFilter,setSubCategoryFilter] = useState("");
    const [contentsDateFilter,setContentsDateFilter] = useState("");
    const [search,setSearch] = useState(null);
    const [bulletinContentsList,setBulletinContentsList] = useState([]);

    const columns = useMemo(() => [
        { accessor: "title", Header: "제목", Cell: ({ value }) => <div className="tdBox" style={{ width: '250px',display:'-webkit-box',WebkitLineClamp:3,WebkitBoxOrient:'vertical' }}>{value}</div>, },
        { accessor: "description", Header: "설명", Cell: ({ value }) => <div className="tdBox" style={{ width: '250px',display:'-webkit-box',WebkitLineClamp:3,WebkitBoxOrient:'vertical' }}>{value}</div>, },
        { accessor: "buttonTitle", Header: "버튼 제목", Cell: ({ value }) => <div className="tdBox" style={{ width: '186px' }}>{value}</div>, },
        { accessor: "buttonUrl", Header: "버튼 URL", Cell: ({ value }) => <div className="tdBox" style={{ width: '152px' }}>{value}</div>, },
        { accessor: "thumbnailImageUrl", Header: "썸네일 이미지", Cell: ({ value }) => {
                const isImage = stringToArray(value).length>0
                return (isImage&&<div className="tdBox" style={{ width: '109px', height:'100%' }}><img src={stringToArray(value)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></div>) 
            }
        },
        { accessor: "createdAt", Header: "생성일", Cell: ({ value }) => <div className="tdBox" style={{ width: '99px' }}>{formatDate(value)}</div>, },
        { accessor: "updatedAt", Header: "최근 수정한 날짜", Cell: ({ value }) => <div className="tdBox" style={{ width: '145px' }}>{formatDatetime(value)}</div>, },
        { accessor: "updatedBy", Header: "최근 수정 유저", Cell: ({ value }) => <div className="tdBox" style={{ width: '200px' }}>{value}</div>, },
    ],[bulletinContentsList]);
    

    /** ************************************************************
     * Get bulletin contents data
     ************************************************************ */
    useEffect(()=>{
        // 발행된 주보, 발행 예정 주보 갯수 계산.
    },[]);

    useEffect(() => {
        if(isAuthenticated) get_bulletin_contents();
    }, [isAuthenticated, accessToken]);
    
    useEffect(() => {
        if(isAuthenticated) get_bulletin_contents();
    }, [page]);



    const add_bulletin_contents = () => {
        navigate("/bulletin/bulletinContents/editor")
    }
    const handleDateFilter = (date) => {
        // console.log("handleDateFilter",date);
    } 
    const handleSearchValue = (value) => {
        setSearch(value);
        // console.log("handleSearchValue",value);
    }
    const handleSearch = () => {
        // console.log("handleSearch",search);
    } 
    const handlePageChange = (page) => {
        setPage(page);  // 새로운 페이지로 상태 업데이트
    };

    
    const handleEdit = (selectedRowId) => {
        // console.log("handleEdit",selectedRowId);
        navigate("/bulletin/bulletinContents/editor/"+selectedRowId)
    };
    const handleDelete = (selectedRowId) => {
        // console.log("handleDelete",selectedRowId);
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
        delete_bulletin_contents(selectedRowId);
    };


    /** ************************************************************
     * Bulletin Contents Functions
     ************************************************************ */
    const get_bulletin_contents = async () => {
        try {
            const response = await axios.get(API_SERVER+'/bulletins/contents',{
                params: { page, limit }
            });
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log("[get_bulletin_contents]","[GET]",API_SERVER+'/bulletins/contents',responseData);
                setLoading(false);
                if(responseData.list) setBulletinContentsList(responseData.list);
                if(responseData.listCount) setContentsCount(responseData.listCount);
                setItemCount(responseData.length);
                setInitData(true);
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.error("error",error);
            if (error.response) {
                console.error("error.response",error.response);
            } else if (error.request) {
                console.error('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.error('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        } finally {
            setLoading(false);
        }
    }

    const delete_bulletin_contents = async (bulletinId) => {
        setLoading(true);
        try {
            const response = await axios.delete(API_SERVER+'/BULLETINS/CONTENTS/'+bulletinId);
            if (response.status === 200) {
                setBulletinContentsList([]);
                await get_bulletin_contents();
            } else {
                console.log("failed login");
            }
        } 
        catch (error) {
            console.error("error",error);
            if (error.response) {
                console.error("error.response",error.response);
            } else if (error.request) {
                console.error('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.error('로그인 실패: ' + error.message);
            }
            logout(()=>{navigate("/login")});
        } 
    }
    
    const formatDate = (date) => {
        if(!date) return "-";
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        return `${year}.${month}.${day}`;
    }
    const formatDatetime = (date) => {
        if(!date) return "-";
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hour = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const minute = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();
        const second = today.getSeconds()>9? today.getSeconds() : "0"+today.getSeconds();
        return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
    }
    const stringToArray = (str) => {
        if (typeof str !== "string") return [];

        // 빈 배열 형태의 문자열 처리
        if (str.trim() === "[]") return [];

        // 정상적인 URL,URL,URL 형태의 문자열을 배열로 변환
        return str.split(",").map((item) => item.trim());
    }

    if(!initData) return;

    return (
        <div className="contentsPage bulletinContentsListWrap">
            {loading&& <LoadingSpinner/>}
            
            {/* Title Container */}
            <CustomTitle 
                title="주보 컨텐츠" 
                titleSize="24px"
                titleWeight="500"

                buttonTitle="주보 컨텐츠 생성" 
                buttonOnClick={add_bulletin_contents}
                buttonBackgroundColor={COLORS.white} 
                buttonFontColor={COLORS.black3} 
                buttonPadding="8px 16px"
                buttonBorder={"1px solid "+ COLORS.black3} />
            <HorizenLine color="#999999" />
            

            {/* Filter & Search Container */}
            <div className="filterSearchContainer">
                {/* 총 개수 */}
                <span className="filterWrap">
                    총 <span style={{fontWeight:700}}>{itemCount}</span>개
                </span>

                {/* 기간 */}
                <span className="filterWrap" style={{display:'flex', alignItems:'center'}}>
                    <span className="filterTitle">기간</span>
                    <span>
                        <CustomDateSelect 
                        callback={handleDateFilter}
                        width={"120px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}/>
                    </span>
                </span>

                {/* 검색 */}
                <span className="filterWrap" style={{display:'flex', alignItems:'center'}}>
                    <span>
                        <CustomInputBox 
                        callback={handleSearchValue}
                        placeholder={"검색"}
                        width={"103px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        icon={ICONS.SEARCH_MONO}
                        iconHandler={handleSearch}/>
                    </span>
                </span>
            </div>

            {/* Pagination Section */}
            <div className="tableContainer">
                <Pagination 
                totalContents={contentsCount}
                contentsLimit={limit}
                currentPage={page}
                callback={handlePageChange}/>
            </div>

            {/* List Section */}
            <div className="tableContainer">
                <CustomTable
                    data={bulletinContentsList}
                    columns={columns}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    listDot={true}
                    idField={"bulletinContentId"}
                    userRole={userRoles}
                    roleType={roleType} />
            </div>
        </div>
    );
}
export default BulletinContentsListPage;