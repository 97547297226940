import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// contexts
import { useAuth } from '../../../contexts/AuthContext';

// constants
import { COLORS } from '../../../constants'

// Components
import { CustomContentsTitle, CustomInputBox, CustomDateSelect, CustomHourSelect, CustomMinSelect, CustomTextArea, CustomButtonRadio, CustomCheckbox, CustomImageMultiUpload, CustomButton } from "../../../components"


import './AdsEditorPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

const CustomButtonRadioSample1 = [
    {"name":"소식", "value":"NEWS"}, 
    {"name":"모집", "value":"RECRUIT"}
]
const CustomButtonRadioSample2 = [
    {"name":"공동체", "value":"COMMUNITY"}, 
    {"name":"교회", "value":"CHURCH"}
]
const CustomButtonRadioSample3 = [
    {"name":"발행", "value":1}, 
    {"name":"마감", "value":2}
]

function AdsEditorPage(){
    const { isAuthenticated, checkAuth, accessToken, logout, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];
    
    const { id } = useParams();
    const { communityId } = useAuth();

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);

    const [editAdsList,setEditAdsList] = useState(null);

    const [adIsShowing,setAdIsShowing] = useState(null);
    const [adIsShowingError,setAdIsShowingError] = useState(null);

    const [adsTitle,setAdsTitle] = useState(null);
    const [adsTitleError,setAdsTitleError] = useState(null);

    const [adsDesc,setAdsDesc] = useState(null);

    const [adsThumbnailImageFiles, setAdsThumbnailImageFiles] = useState([]);
    const [adsThumbnailImageUrls, setAdsThumbnailImageUrls] = useState([]);
    const [deleteAdsThumbnailImages, setDeleteAdsThumbnailImages] = useState([]);
    const [adsThumbnailImageCount, setAdsThumbnailImageCount] = useState(0);
    const [adsThumbnailImageError, setAdsThumbnailImageError] = useState(null);

    const [adsImageFiles, setAdsImageFiles] = useState([]);
    const [adsImageUrls, setAdsImageUrls] = useState([]);
    const [deleteAdsImages, setDeleteAdsImages] = useState([]);
    const [adsImageCount, setAdsImageCount] = useState(0);
    const [adsImageError, setAdsImageError] = useState(null);
    
    const [adsPublishDate,setAdsPublishDate] = useState(null);
    const [adsPublishDateError,setAdsPublishDateError] = useState(null);
    const [adsPublishHour,setAdsPublishHour] = useState(null);
    const [adsPublishHourError,setAdsPublishHourError] = useState(null);
    const [adsPublishMin,setAdsPublishMin] = useState(null);
    const [adsPublishMinError,setAdsPublishMinError] = useState(null);

    const [adType,setAdType] = useState(null);
    const [adTypeError,setAdTypeError] = useState(null);
    const [adPublisherType,setAdPublisherType] = useState(null);
    const [adPublisherTypeError,setAdPublisherTypeError] = useState(null);

    const [adsButtonTitle,setAdsButtonTitle] = useState(null);
    const [adsButtonUrl,setAdsButtonUrl] = useState(null);


    const [validation,setValidation] = useState(true);

    useEffect(()=>{
        setLoading(false);
        if(checkAuth()){
            if(id) get_ads(id);
        };
    },[]);    

    useEffect(()=>{
        if(editAdsList){
            setAdsTitle(editAdsList.title);
            setAdsDesc(editAdsList.description);

            setAdIsShowing(editAdsList.isShowing);
            setAdsThumbnailImageUrls(editAdsList.thumbnailImageUrl? editAdsList.thumbnailImageUrl.split(","):[]);
            setAdsImageUrls(editAdsList.imageUrl? editAdsList.imageUrl.split(","):[]);

            setAdsPublishDate(new Date(editAdsList.publishDate));
            setAdsPublishHour(new Date(editAdsList.publishDate).getHours());
            setAdsPublishMin(new Date(editAdsList.publishDate).getMinutes());
            
            setAdType(editAdsList.adType);
            setAdPublisherType(editAdsList.adPublisherType);
            setAdsButtonTitle(editAdsList.buttonTitle);
            setAdsButtonUrl(editAdsList.buttonUrl);
        }
    },[editAdsList]);

    useEffect(()=>{
        if(uploadComplete){
            navigate('/ads/ads/list', { replace: true });
        }
    },[uploadComplete]);


    const handleIsShowing = (isShowing) => {
        setAdIsShowing(isShowing);
    }
    const handleAdsTitle = (title) => {
        setAdsTitle(title);
    }
    const handleAdsDesc = (desc) => {
        setAdsDesc(desc);
    }
    const handleAdsThumbnailImageUrl = (file,url) => {
        setAdsThumbnailImageFiles(file);
        setAdsThumbnailImageUrls(url);
        setAdsThumbnailImageError(null);
    }
    const handleAdsImageUrl = (file,url) => {
        setAdsImageFiles(file);
        setAdsImageUrls(url);
        setAdsImageError(null);
    }
    const handleAdsPublishDate = (adsPublishDate) => {
        setAdsPublishDate(adsPublishDate);
        setAdsPublishDateError(null);
        // console.log("setAdsPublishDate",adsPublishDate);
    }
    const handleAdsPublishHour = (adsPublishHour) => {
        setAdsPublishHour(adsPublishHour);
        setAdsPublishHourError(null);
        // console.log("setAdsPublishHour",adsPublishHour);
    }
    const handleAdsPublishMin = (adsPublishMin) => {
        setAdsPublishMin(adsPublishMin);
        setAdsPublishMinError(null);
        // console.log("setAdsPublishMin",adsPublishMin);
    }
    const handleAdType = (type) => {
        setAdType(type);
        setAdTypeError(null);
        // console.log("setAdType",type);
    }
    const handleAdPublisherType = (publisherType) => {
        setAdPublisherType(publisherType);
        setAdPublisherTypeError(null);
        // console.log("setAdPublisherType",publisherType);
    }
    const handleAdsButtonTitle = (title) => {
        setAdsButtonTitle(title);
    }
    const handleAdsButtonUrl = (url) => {
        setAdsButtonUrl(url);
    }
    
    

    useEffect(()=>{
        setAdsThumbnailImageCount(adsThumbnailImageUrls.length);
    },[adsThumbnailImageUrls]);
    useEffect(()=>{
        setAdsImageCount(adsImageUrls.length);
    },[adsImageUrls]);



    /** ************************************************************
     * Ads Functions
     ************************************************************ */
    const get_ads = async () => {
        try {
            const response = await axios.get(API_SERVER+'/communities/ads/'+id);
            if (response.status === 200) {
                const responseData = response.data.data;
                setEditAdsList(responseData[0]);
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        } finally {
            setLoading(false);
        }
    }



    
    const upload = async () => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 

        let state = true;

        // 제목 입력 여부
        if(!adsTitle) {
            setAdsTitleError("제목을 입력해주세요.");
            state &= false;
        } else{
            setAdsTitleError(null);
            state &= true;
        } 

        // 썸네일 이미지 여부
        if(adsThumbnailImageUrls==[] || adsThumbnailImageUrls.length==0) {
            setAdsThumbnailImageError("이미지를 추가해주세요");
            state &= false;
        } else{
            setAdsThumbnailImageError(null);
            state &= true;
        } 


        // 이미지 여부
        if(adsImageUrls==[] || adsImageUrls.length==0) {
            setAdsImageError("이미지를 추가해주세요");
            state &= false;
        } else{
            setAdsImageError(null);
            state &= true;
        } 

        if(!adsPublishDate){
            setAdsPublishDateError("날짜를 입력해주세요.");
            state &= false;
        }
        else{
            setAdsPublishDateError(null);
            state &= true;
        }

        if(adsPublishHour === null || adsPublishHour === undefined){
            setAdsPublishHourError("시간을 입력해주세요.");
            state &= false;
        }
        else{
            setAdsPublishHourError(null);
            state &= true;
        } 

        if(adsPublishMin === null || adsPublishMin === undefined){
            setAdsPublishMinError("분을 입력해주세요.");
            state &= false;
        }
        else{
            setAdsPublishMinError(null);
            state &= true;
        }

        if(!adType){
            setAdTypeError("분류를 선택해주세요.");
            state &= false;
        }
        else{
            setAdTypeError(null);
            state &= true;
        }

        if(!adPublisherType){
            setAdPublisherTypeError("발행처를 선택해주세요.");
            state &= false;
        }
        else{
            setAdPublisherTypeError(null);
            state &= true;
        }

        if(!state) {
            setLoading(false);
            return;
        }

        if(state){
            setIsUploading(true);

            let adsThumbnailImageUrlsTemp = []
            if (adsThumbnailImageUrls) {
                const adsThumbnailImageUrlsWithIndex = adsThumbnailImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                adsThumbnailImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    adsThumbnailImageUrlsTemp.push(temp);
                });
            }

            let adsImageUrlsTemp = []
            if (adsImageUrls) {
                const adsImageUrlsWithIndex = adsImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                adsImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    adsImageUrlsTemp.push(temp);
                });
            }

            const data = {
                communityId: parseInt(communityId),
                isShowing: adIsShowing? 1:0,
                title: adsTitle,
                description: adsDesc,
                publishDate: getKSTISOString(adsPublishDate,adsPublishHour,adsPublishMin),
                adType: adType,
                adPublisherType: adPublisherType,
                buttonTitle: adsButtonTitle,
                buttonUrl: adsButtonUrl,
                imageUrl: adsImageUrlsTemp,
                thumbnailImageUrl: adsThumbnailImageUrlsTemp,
            }

            // console.log("data",data);
            update_ads(data);
        }
    }

    const update_ads = async (data) => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 

        try {
            let response;

            const formData = new FormData();
            formData.append("data", JSON.stringify(data)); 

            // 파일 데이터 추가 (배열로 추가)
            if (adsImageFiles) {
                adsImageFiles.forEach((file) => formData.append("imageFile", file));
            }
            if (adsThumbnailImageFiles) {
                adsThumbnailImageFiles.forEach((file) => formData.append("thumbnailImageFile", file));
            }

            if (id){
                if(ENV) console.log("[PUT]",API_SERVER+'/communities/ads/'+id,formData);
                response = await axios.put(API_SERVER+'/communities/ads/'+id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }
            else{
                if(ENV) console.log("[POST]",API_SERVER+'/communities/ads',formData);
                response = await axios.post(API_SERVER+'/communities/ads', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }

            if (response.status === 200) {
                // const responseData = response.data.data;
                // console.log(responseData);
                // setWorshipList(responseData);
                setIsUploading(false);
                setLoading(false);
                setUploadComplete(true);
            } else {
                console.log("failed to add bulletin");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.log('로그인 실패: ' + error.message);
            }
        }
    }
    const getKSTISOString = (date, hour, min) => {
        // bulletinDate가 문자열이면 Date 객체로 변환
        const dateTemp = typeof date === 'string' ? new Date(date) : date;
    
        // 시간과 분 설정
        dateTemp.setHours(hour);
        dateTemp.setMinutes(min);
    
        // UTC 기준에서 한국 시간으로 변환
        const kstDate = new Date(dateTemp);
    
        // ISO 문자열 반환
        return new Date(dateTemp).toISOString();
    };


    return (
        <div className="contentsPage adsEditorWrap">
            <div className="adsEditorContainer">

                {/* 게시 여부 */}
                <div className="adsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"게시 여부"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomCheckbox 
                    callback={handleIsShowing}
                    data={adIsShowing}
                    width={"70px"}
                    border={"1px solid "+ COLORS.grayD}
                    backgroundColor={COLORS.white}
                    fontColor={COLORS.black9}
                    padding={"8px 16px"}/>
                </div>

                {/* 제목 */}
                <div className="adsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"제목"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <CustomInputBox 
                    callback={handleAdsTitle}
                    data={adsTitle}
                    placeholder={"제목 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    textLength={30}
                    errorText={adsTitleError}/>
                </div>

                {/* 설명 */}
                <div className="adsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"설명"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}/>
                    <CustomTextArea 
                    callback={handleAdsDesc}
                    data={adsDesc? adsDesc:""}
                    placeholder={"설명 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    line={6}
                    textLength={2000}/>
                </div>

                {/* 썸네일 이미지 */}
                <div className="adsEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"썸네일 이미지"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={adsThumbnailImageCount}
                            lengthMax={1}
                            require={true} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handleAdsThumbnailImageUrl(images,urls)}}
                            imagesUrl={adsThumbnailImageUrls}
                            imageFile={adsThumbnailImageFiles}
                            id={"adsThumbnailImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={1}
                            errorText={adsThumbnailImageError} />
                        </span>
                    </div>
                </div>

                {/* 이미지 리스트 */}
                <div className="adsEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"이미지 리스트"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={adsImageCount}
                            lengthMax={10}
                            require={true} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handleAdsImageUrl(images,urls)}}
                            imagesUrl={adsImageUrls}
                            imageFile={adsImageFiles}
                            id={"adsImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={10}
                            errorText={adsImageError} />
                        </span>
                    </div>
                </div>
                
                {/* 게시일 */}
                <div className="adsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"게시일"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>
                        <CustomDateSelect 
                        callback={handleAdsPublishDate}
                        data={adsPublishDate}
                        width={"124px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={adsPublishDateError}/>
                        <CustomHourSelect 
                        callback={handleAdsPublishHour}
                        data={adsPublishHour}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={adsPublishHourError}/>
                        <CustomMinSelect 
                        callback={handleAdsPublishMin}
                        data={adsPublishMin}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={adsPublishMinError}/>
                    </div>
                </div>
                
                {/* 분류&발행처 여부 */}
                <div className="worshipEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        {/* 분류 */}
                        <span style={{width:'auto', display:'flex',flexDirection:'row', alignItems:'center',marginRight:'16px'}}>
                            <CustomContentsTitle 
                            title={"분류"}
                            margin={'0px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            styles={{marginBottom:'0px', marginRight:'8px'}}
                            require={true}/>
                            <CustomButtonRadio 
                            callback={handleAdType}
                            label={CustomButtonRadioSample1}
                            data={adType}
                            width={"70px"}
                            border={"1px solid "+ COLORS.grayD}
                            backgroundColor={COLORS.white}
                            fontColor={COLORS.black9}
                            padding={"8px 16px"}
                            errorText={adTypeError}/>
                        </span>
                        {/* 발행처 */}
                        <span style={{width:'auto', display:'flex',flexDirection:'row', alignItems:'center'}}>
                            <CustomContentsTitle 
                            title={"발행처"}
                            margin={'0px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            styles={{marginBottom:'0px', marginRight:'8px'}}
                            require={true}/>
                            <CustomButtonRadio 
                            callback={handleAdPublisherType}
                            label={CustomButtonRadioSample2}
                            data={adPublisherType}
                            width={"72px"}
                            border={"1px solid "+ COLORS.grayD}
                            backgroundColor={COLORS.white}
                            fontColor={COLORS.black9}
                            padding={"8px 16px"}
                            errorText={adPublisherTypeError}/>
                        </span>
                    </div>
                </div>

                {/* 버튼 제목 */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"버튼 제목"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomInputBox 
                    callback={handleAdsButtonTitle}
                    data={adsButtonTitle}
                    placeholder={"버튼 제목 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    textLength={20}/>
                </div>

                {/* 버튼 URL */}
                <div className="worshipEditorContentsRow">
                    <CustomContentsTitle 
                    title={"버튼 URL"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomInputBox 
                    callback={handleAdsButtonUrl}
                    placeholder={"버튼 URL 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}/>
                </div>

                {Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)) &&
                <div className="bulletinContentsEditorContentsRow contentsButtonBox">
                    <CustomButton 
                    title={"취소"}
                    onClick={() => {navigate(-1)}}
                    width={"auto"}
                    padding={"8px 16px"}
                    backgroundColor={COLORS.white}
                    border={"1px solid"+COLORS.black3} />
                    <CustomButton 
                    title={"등록"}
                    onClick={upload}
                    width={"auto"}
                    padding={"8px 16px"}
                    fontColor={validation? COLORS.white:COLORS.black9}
                    backgroundColor={validation? COLORS.black3:COLORS.grayED}
                    border={"1px solid"+validation? COLORS.black3:COLORS.grayED}/>
                </div>
                }

            </div>
        </div>
    );
}
export default AdsEditorPage;
