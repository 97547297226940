import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// constants
import { COLORS } from '../../../constants'

// contexts
import { useAuth } from '../../../contexts/AuthContext';

// Components
import { CustomContentsTitle, CustomInputBox, CustomDateSelect, CustomHourSelect, CustomMinSelect, CustomTextArea, CustomButton, CustomImageMultiUpload, LoadingSpinner } from "../../../components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';


import './BulletinEditorPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

function BulletinEditorPage(){

    const bulletinJSONDefault = {
        "index": 0,
        "title": "",
        "contents": []
    }
    
    const { isAuthenticated, checkAuth, accessToken, logout, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];
    
    const { id } = useParams();

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState(false);

    const { communityId } = useAuth();

    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);

    const [bulletinJSON, setBulletinJSON] = useState([bulletinJSONDefault]);

    const [editBulletin, setEditBulletin] = useState(null);

    // Worship List
    const [worshipList,setWorshipList] = useState([]);
    const [worshipCount, setWorshipCount] = useState(0);
    const [selectedWorship, setSelectedWorship] = useState([]);

    // Bulletin Contents List
    const [bulletinContentsList,setBulletinContentsList] = useState([]);
    const [selectedBulletinContents, setSelectedBulletinContents] = useState([]);

    const [bulletinDate,setBulletinDate] = useState(null);
    const [bulletinDateError,setBulletinDateError] = useState(null);
    const [bulletinHour,setBulletinHour] = useState(null);
    const [bulletinHourError,setBulletinHourError] = useState(null);
    const [bulletinMin,setBulletinMin] = useState(null);
    const [bulletinMinError,setBulletinMinError] = useState(null);

    const [bulletinPublishDate,setBulletinPublishDate] = useState(null);
    const [bulletinPublishDateError,setBulletinPublishDateError] = useState(null);
    const [bulletinPublishHour,setBulletinPublishHour] = useState(null);
    const [bulletinPublishHourError,setBulletinPublishHourError] = useState(null);
    const [bulletinPublishMin,setBulletinPublishMin] = useState(null);
    const [bulletinPublishMinError,setBulletinPublishMinError] = useState(null);

    const [bulletinCoverImageFiles, setBulletinCoverImageFiles] = useState([]);
    const [bulletinCoverImageUrls, setBulletinCoverImageUrl] = useState([]);
    const [bulletinCoverImageCount, setBulletinCoverImageCount] = useState(0);
    const [bulletinCoverImageUrlsError, setBulletinCoverImageError] = useState(null);
    
    const [bulletinTitle,setBulletinTitle] = useState(null);
    const [bulletinTitleError,setBulletinTitleError] = useState(null);

    const [bulletinDesc,setBulletinDesc] = useState(null);
    const [bulletinDescError,setBulletinDescError] = useState(null);

    const [validation,setValidation] = useState(false);

    useEffect(()=>{
        setLoading(false);
        if(checkAuth()){
            if(id) get_bulletin(id);
            get_worships();
            get_bulletin_contents();
        };
    },[]);
    
    useEffect(()=>{
        if(editBulletin){
            setBulletinDate(new Date(editBulletin.bulletinDate));
            setBulletinHour(new Date(editBulletin.bulletinDate).getHours());
            setBulletinMin(new Date(editBulletin.bulletinDate).getMinutes());
            setBulletinPublishDate(new Date(editBulletin.publishDate));
            setBulletinPublishHour(new Date(editBulletin.publishDate).getHours());
            setBulletinPublishMin(new Date(editBulletin.publishDate).getMinutes());
            setBulletinTitle(editBulletin.title);
            setBulletinDesc(editBulletin.description);
            setBulletinCoverImageUrl(editBulletin.thumbnailImageUrl? stringToArray(editBulletin.thumbnailImageUrl):[]);
            // console.log("editBulletin",editBulletin.bulletinContents);
            const editBulletinContents = JSON.parse(editBulletin.contents);
            setBulletinJSON(editBulletin.contents? editBulletinContents.bulletinTabs:[bulletinJSONDefault]);
        }
        setInitData(true)
    },[editBulletin]);

    useEffect(()=>{
        let result = dataValidate();
        if(result){
            setValidation(true); 
        }
        else {
            setValidation(false); 
        }
    },[bulletinDate,bulletinHour,bulletinMin,bulletinPublishDate,bulletinPublishHour,bulletinPublishMin])
    
    useEffect(()=>{
        setBulletinCoverImageCount(bulletinCoverImageUrls.length);        
    },[bulletinCoverImageUrls]);

    useEffect(()=>{
        if(uploadComplete){
            navigate('/bulletin/bulletin/list', { replace: true });
        }
    },[uploadComplete]);
    

    const dataValidate = () => {
        if(bulletinDate!=null&&bulletinHour!=null&&bulletinMin!=null&&bulletinPublishDate!=null&&bulletinPublishHour!=null&&bulletinPublishMin!=null) return true;
        return false;
    }

    /** ************************************************************
     * Contents Functions
     ************************************************************ */
    const select_content = (content,JSONIdx,type) => {
        setBulletinJSON((prevSelected) => {
            // 기존 배열 복사
            const newBulletinJSON = [...prevSelected];
        
            // 해당 JSONIdx 위치에 contents 배열이 이미 존재하는지 확인
            if (newBulletinJSON[JSONIdx]) {
                const currentContents = newBulletinJSON[JSONIdx].contents;
                const newContent = {
                    index: currentContents.length, // 현재 contents 배열의 길이를 인덱스로 사용
                    type: type,
                    id:  type=='worship'? content.worshipId: content.bulletinContentId,
                    title: type=='worship'? content.scripture: content.title,
                    date: type=='worship'? content.worshipDate: content.createdAt,
                };
                newBulletinJSON[JSONIdx].contents = [...currentContents, newContent];
            } else {
                // 필요한 경우 새 인덱스 추가
                newBulletinJSON[JSONIdx] = {
                    ...bulletinJSONDefault,
                    index: JSONIdx,
                    contents: [{
                        index: 0, // 첫 번째 항목이므로 인덱스는 0
                        type: type,
                        id:  type=='worship'? content.worshipId: content.bulletinContentId,
                        title: type=='worship'? content.scripture: content.title,
                        date: type=='worship'? content.worshipDate: content.createdAt,
                    }],
                };
            }
            return newBulletinJSON;
        });
    }
    const remove_contents = (content, JSONIdx, index) => {
        setBulletinJSON((prevBulletinJSON) => {
            const newBulletinJSON = [...prevBulletinJSON];
            if (newBulletinJSON[JSONIdx]) {
                // 해당 JSONIdx에서 contents 배열에서 index에 해당하는 항목 제거
                newBulletinJSON[JSONIdx].contents = newBulletinJSON[JSONIdx].contents.filter((_, contentIdx) => contentIdx !== index);
            }
            return newBulletinJSON;
        });
    }
    const move_contents = (JSONIdx, idx, direction) => {
        setBulletinJSON((prevBulletinJSON) => {
            const newBulletinJSON = [...prevBulletinJSON];
    
            // 특정 JSONIdx의 contents 배열이 있는지 확인
            if (newBulletinJSON[JSONIdx]) {
                const newContents = [...newBulletinJSON[JSONIdx].contents];
    
                // 위로 이동
                if (direction === 'up' && idx > 0) {
                    [newContents[idx - 1], newContents[idx]] = [newContents[idx], newContents[idx - 1]];
                }
    
                // 아래로 이동
                if (direction === 'down' && idx < newContents.length - 1) {
                    [newContents[idx], newContents[idx + 1]] = [newContents[idx + 1], newContents[idx]];
                }
    
                // 순서 변경 후 새로운 순서에 맞게 index 값 재조정
                newContents.forEach((content, newIndex) => {
                    content.index = newIndex;
                });

                // 변경된 contents 배열을 반영
                newBulletinJSON[JSONIdx].contents = newContents;
            }
    
            return newBulletinJSON;
        });
    };
    const handle_bulletin_contentsTitle = (title, JSONIdx) => {
        setBulletinJSON((prevBulletinJSON) => {
            const newBulletinJSON = [...prevBulletinJSON];
            newBulletinJSON[JSONIdx].title = title;
            return newBulletinJSON;
        });
    };
    const add_bulletin_category = () => {
        setBulletinJSON((prevBulletinJSON) => {
            const newBulletinJSON = [...prevBulletinJSON];
            const newCategory = {
                ...bulletinJSONDefault, // 기본 속성 복사
                index: newBulletinJSON.length, // 새로운 index 설정
                title: '', // 새로운 카테고리의 제목
                contents: [], // 새로운 카테고리의 컨텐츠
            };
            newBulletinJSON.push(newCategory);
            return newBulletinJSON;
        });
    };
    const remove_bulletin_category = (JSONIdx) => {
        setBulletinJSON((prevBulletinJSON) => {
            // 해당 인덱스의 카테고리 제거
            const newBulletinJSON = prevBulletinJSON.filter((_, idx) => idx !== JSONIdx);
    
            // 인덱스를 재조정
            return newBulletinJSON.map((bulletin, idx) => ({
                ...bulletin,
                index: idx
            }));
        });
    };
    const move_bulletin_category = (idx, direction) => {
        setBulletinJSON((prevBulletinJSON) => {
            const newBulletinJSON = [...prevBulletinJSON];
    
            // 위로 이동
            if (direction === 'up' && idx > 0) {
                [newBulletinJSON[idx - 1], newBulletinJSON[idx]] = [newBulletinJSON[idx], newBulletinJSON[idx - 1]];
            }
    
            // 아래로 이동
            if (direction === 'down' && idx < newBulletinJSON.length - 1) {
                [newBulletinJSON[idx], newBulletinJSON[idx + 1]] = [newBulletinJSON[idx + 1], newBulletinJSON[idx]];
            }
    
            // 인덱스를 재조정
            return newBulletinJSON.map((bulletin, index) => ({
                ...bulletin,
                index: index // 각 카테고리의 인덱스를 재조정
            }));
        });
    };



    /** ************************************************************
     * Bulletin Functions
     ************************************************************ */
    const get_bulletin = async (bulletinId) => {
        try {
            const response = await axios.get(API_SERVER+'/bulletins/'+bulletinId);
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log("[get_bulletin]","[get]",API_SERVER+'/bulletins/'+bulletinId,responseData[0]);
                setEditBulletin(responseData[0]);

                let published = 0;
                let upcoming = 0;
                const today = new Date();
                for (let i=0; i<responseData.length; i++) {
                    let publishDate = new Date(responseData[i].publishDate);
                    if (publishDate > today) {
                        upcoming++;
                    }
                    else {
                        published++;
                    }
                }
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        } finally {
            setLoading(false);
        }
    }


    /** ************************************************************
     * Worship Functions
     ************************************************************ */
    const get_worships = async () => {
        try {
            const response = await axios.get(API_SERVER+'/worships');
            if (response.status === 200) {
                const responseData = response.data.data;
                // console.log(responseData);
                if(responseData.list) setWorshipList(responseData.list);
                if(responseData.listCount) setWorshipCount(responseData.listCount);
                setLoading(false);
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.log('로그인 실패: ' + error.message);
            }
            logout(()=>{navigate("/login")});
        } finally {
            setLoading(false);
        }
    }

    /** ************************************************************
     * Bulletin Contents Functions
     ************************************************************ */
    const get_bulletin_contents = async () => {
        try {
            const response = await axios.get(API_SERVER+'/bulletins/contents');
            if (response.status === 200) {
                const responseData = response.data.data;
                // console.log(responseData);
                setLoading(false);
                if(responseData.list) setBulletinContentsList(responseData.list);
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        }
    }


    const handleBulletinDate = (bulletinDate) => {
        // UTC 날짜 객체 생성
        const date = new Date(bulletinDate);
    
        // UTC+9(KST)로 변환
        const kstDate = new Date(date.getTime() + (9 * 60 * 60 * 1000));
    
        // KST 날짜를 원하는 형식으로 출력
        const formattedDate = kstDate.toISOString().slice(0, 10);
        
        setBulletinDate(date);
        setBulletinDateError(null);
        // setBulletinDate(formattedDate);
        // console.log("bulletinDate",formattedDate);
    }
    const handleBulletinHour = (bulletinHour) => {
        setBulletinHour(bulletinHour);
        setBulletinHourError(null);
        // console.log("bulletinHour",bulletinHour);
    }
    const handleBulletinMin = (bulletinMin) => {
        setBulletinMin(bulletinMin);
        setBulletinMinError(null);
        // console.log("bulletinMin",bulletinMin);
    }
    const handleBulletinPublishDate = (bulletinPublishDate) => {
        // UTC 날짜 객체 생성
        const date = new Date(bulletinPublishDate);
    
        // UTC+9(KST)로 변환
        const kstDate = new Date(date.getTime() + (9 * 60 * 60 * 1000));
    
        // KST 날짜를 원하는 형식으로 출력
        const formattedDate = kstDate.toISOString().slice(0, 10);

        setBulletinPublishDate(formattedDate);
        setBulletinPublishDateError(null);
        // console.log("bulletinPublishDate",formattedDate);
    }
    const handleBulletinPublishHour = (bulletinPublishHour) => {
        setBulletinPublishHour(bulletinPublishHour);
        setBulletinPublishHourError(null);
        // console.log("bulletinPublishHour",bulletinPublishHour);
    }
    const handleBulletinPublishMin = (bulletinPublishMin) => {
        setBulletinPublishMin(bulletinPublishMin);
        setBulletinPublishMinError(null);
        // console.log("bulletinPublishMin",bulletinPublishMin);
    }
    const handleBulletinCoverImageFile = (file,url) => {
        setBulletinCoverImageFiles(file);
        setBulletinCoverImageUrl(url);
    }
    const handleBulletinTitle = (title) => {
        setBulletinTitle(title);
        // console.log("bulletinTitle",title);
    }
    const handleBulletinDesc = (desc) => {
        setBulletinDesc(desc);
        // console.log("bulletinDesc",desc);
    }
    
    

    const upload = async() => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 

        let state = true;

        // 주보 날짜
        if(bulletinDate==null) {
            setBulletinDateError("날짜를 입력해주세요.");
            state &= false;
        } else{
            setBulletinDateError(null);
            state &= true;
        }

        // 주보 시간
        if(bulletinHour==null) {
            setBulletinHourError("시간을 입력해주세요.");
            state &= false;
        } else{
            setBulletinHourError(null);
            state &= true;
        }

        // 주보 분
        if(bulletinMin==null) {
            setBulletinMinError("분을 입력해주세요.");
            state &= false;
        } else{
            setBulletinMinError(null);
            state &= true;
        }

        // 발행 날짜
        if(bulletinPublishDate==null) {
            setBulletinPublishDateError("날짜를 입력해주세요.");
            state &= false;
        } else{
            setBulletinPublishDateError(null);
            state &= true;
        }

        // 발행 시간
        if(bulletinPublishHour==null) {
            setBulletinPublishHourError("시간을 입력해주세요.");
            state &= false;
        } else{
            setBulletinPublishHourError(null);
            state &= true;
        }

        // 발행 분
        if(bulletinPublishMin==null) {
            setBulletinPublishMinError("분을 입력해주세요.");
            state &= false;
        } else{
            setBulletinPublishMinError(null);
            state &= true;
        }

        // 주보 제목
        if(bulletinTitle==null) {
            setBulletinTitleError("제목을 입력해주세요.");
            state &= false;
        } else{
            setBulletinTitleError(null);
            state &= true;
        }

        // 주보 설명
        if(bulletinDesc==null) {
            setBulletinDescError("설명을 입력해주세요.");
            state &= false;
        } else{
            setBulletinDescError(null);
            state &= true;
        }

        if(!state) {
            setLoading(false);
            return;
        }

        if(state){
            setIsUploading(true);

            let bulletinCoverImageUrlsTemp = [];
            if (bulletinCoverImageUrls) {
                const bulletinCoverImageUrlsWithIndex = bulletinCoverImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                bulletinCoverImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    bulletinCoverImageUrlsTemp.push(temp);
                });
            }

            const data = {
                communityId: communityId,
                bulletinDate: getKSTISOString(bulletinDate,bulletinHour,bulletinMin),
                publishDate: getKSTISOString(bulletinPublishDate,bulletinPublishHour,bulletinPublishMin),
                title: bulletinTitle,
                description: bulletinDesc,
                thumbnailImageUrl: bulletinCoverImageUrlsTemp,
                contents: JSON.stringify(
                    {
                      "bulletinTabs": bulletinJSON
                    })
            }
            update_bulletin(data);
        }
    }

    const update_bulletin = async (data) => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
        try {
            let response;

            const formData = new FormData();
            formData.append("data", JSON.stringify(data)); 
            
            if (bulletinCoverImageFiles) {
                bulletinCoverImageFiles.forEach((file) => formData.append("thumbnailImageFile", file));
            }

            if (id){
                if(ENV) console.log("[PUT]",API_SERVER+'/bulletins/'+id,formData);
                response = await axios.put(API_SERVER+'/bulletins/'+id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }
            else{
                if(ENV) console.log("[POST]",API_SERVER+'/bulletins',formData);
                response = await axios.post(API_SERVER+'/bulletins', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }

            if (response.status === 200) {
                // const responseData = response.data.data;
                // console.log(responseData);
                // setWorshipList(responseData);
                setIsUploading(false);
                setLoading(false);
                setUploadComplete(true);
            } else {
                console.log("failed to add bulletin");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.log('로그인 실패: ' + error.message);
            }
        }
    }
    
    const formatDate = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth()+1>9? today.getMonth()+1 : "0"+(today.getMonth()+1);
        const day = today.getDate()>9? today.getDate() : "0"+today.getDate();
        const hour = today.getHours()>9? today.getHours() : "0"+today.getHours();
        const min = today.getMinutes()>9? today.getMinutes() : "0"+today.getMinutes();

        return `${year}.${month}.${day} ${hour}:${min}`;
    }
    const formatWorship = (sundayWorship) => {
        return sundayWorship==null || sundayWorship==1? "주일예배":"주중예배";
    }
    const getKSTISOString = (date, hour, min) => {
        // bulletinDate가 문자열이면 Date 객체로 변환
        const dateTemp = typeof date === 'string' ? new Date(date) : date;
    
        // 시간과 분 설정
        dateTemp.setHours(hour);
        dateTemp.setMinutes(min);
    
        // UTC 기준에서 한국 시간으로 변환
        const kstDate = new Date(dateTemp);
    
        // ISO 문자열 반환
        return new Date(dateTemp).toISOString();
    };
    const stringToArray = (str) => {
        if (typeof str !== "string") return [];

        // 빈 배열 형태의 문자열 처리
        if (str.trim() === "[]") return [];

        // 정상적인 URL,URL,URL 형태의 문자열을 배열로 변환
        return str.split(",").map((item) => item.trim());
    }

    if(!initData) return;

    return (
        <div className="contentsPage bulletinEditorWrap">
            {loading&& <LoadingSpinner/>}

            <div className="bulletinEditorContainer">
                {/* 주보 날짜 시간 */}
                <div className="bulletinEditorContentsRow">
                    <CustomContentsTitle 
                    title={"주보 날짜시간"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>
                        <CustomDateSelect 
                        callback={handleBulletinDate}
                        data={bulletinDate}
                        width={"124px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={bulletinDateError}/>
                        <CustomHourSelect 
                        callback={handleBulletinHour}
                        data={bulletinHour}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={bulletinHourError}/>
                        <CustomMinSelect 
                        callback={handleBulletinMin}
                        data={bulletinMin}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={bulletinMinError}/>
                    </div>
                </div>

                {/* 발행 날짜 시간 */}
                <div className="bulletinEditorContentsRow">
                    <CustomContentsTitle 
                    title={"발행 날짜시간"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>
                        <CustomDateSelect 
                        callback={handleBulletinPublishDate}
                        data={bulletinPublishDate}
                        width={"124px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={bulletinPublishDateError}/>
                        <CustomHourSelect 
                        callback={handleBulletinPublishHour}
                        data={bulletinPublishHour}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={bulletinPublishHourError}/>
                        <CustomMinSelect 
                        callback={handleBulletinPublishMin}
                        data={bulletinPublishMin}
                        width={"80px"}
                        border={"1px solid "+ COLORS.grayD}
                        fontColor={COLORS.black3}
                        padding={"8px"}
                        styles={{marginRight:'8px'}}
                        errorText={bulletinPublishMinError}/>
                    </div>
                </div>

                {/* 주보 커버 이미지 */}
                <div className="bulletinEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        {/* 주보 커버 이미지 */}
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"주보 커버 이미지"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={bulletinCoverImageCount}
                            lengthMax={1}
                            require={false} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handleBulletinCoverImageFile(images,urls)}}
                            imagesUrl={bulletinCoverImageUrls}
                            imageFile={bulletinCoverImageFiles}
                            id={"BulletinCoverImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={1}
                            errorText={bulletinCoverImageUrlsError} />
                        </span>
                    </div>
                </div>
                
                {/* 주보 제목 */}
                <div className="bulletinEditorContentsRow">
                    <CustomContentsTitle 
                    title={"주보 제목"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <CustomInputBox 
                    callback={(title)=> {handleBulletinTitle(title)}}
                    data={bulletinTitle}
                    placeholder={"제목 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    textLength={30}
                    errorText={bulletinTitleError}/>
                </div>

                {/* 주보 설명 */}
                <div className="bulletinEditorContentsRow">
                    <CustomContentsTitle 
                    title={"주보 설명"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <CustomTextArea 
                    callback={(desc)=>{handleBulletinDesc(desc)}}
                    data={bulletinDesc? bulletinDesc: ""}
                    placeholder={"설명 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    line={10}
                    textLength={2000}
                    errorText={bulletinDescError}/>
                </div>

                {/* 주보 탭 */}
                <div className="bulletinEditorContentsRow">
                    <CustomContentsTitle 
                    title={"주보 탭"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}/>
                    <div className="bulletinCategoryControllBox">
                        <span  className="listSideButton" onClick={add_bulletin_category}>
                            <FontAwesomeIcon className="iconBtn" icon={faPlus} />
                        </span>
                    </div>

                    {bulletinJSON.map((tab,JSONidx)=>{
                        return(
                            <div key={JSONidx} className="bulletinCategoryColumn">
                                <div className="selectedListBox" style={{width:'40%'}}>
                                    <div className="bulletinCategoryControllBox">
                                        <CustomInputBox 
                                        data={tab.title}
                                        callback={(title) => { handle_bulletin_contentsTitle(title, JSONidx)}}
                                        placeholder={"제목 입력"}
                                        border={"1px solid "+ COLORS.grayD}
                                        fontColor={COLORS.black3}
                                        padding={"8px"}
                                        textLength={30}
                                        styles={{display:'flex'}}/>
                                        <span className={bulletinJSON.length>1&&JSONidx!=0? "listSideButton":"listSideButton disable"} onClick={()=>{bulletinJSON.length>1&&JSONidx!=0&&move_bulletin_category(JSONidx,'up')}}>
                                            <FontAwesomeIcon className="iconBtn" icon={faAngleUp} />
                                        </span>
                                        <span className={bulletinJSON.length>1&&JSONidx!=bulletinJSON.length-1? "listSideButton":"listSideButton disable"} onClick={()=>{bulletinJSON.length>1&&JSONidx!=bulletinJSON.length-1&&move_bulletin_category(JSONidx,'down')}}>
                                            <FontAwesomeIcon className="iconBtn" icon={faAngleDown} />
                                        </span>
                                        <span className="listSideButton" onClick={()=>{remove_bulletin_category(JSONidx)}}>
                                            <FontAwesomeIcon className="iconBtn" icon={faMinus} />
                                        </span>

                                    </div>
                                    <div className="listScrolWrap">
                                    {tab.contents.map((content,index)=>{
                                        return (
                                            <p className="selectedList" key={index}>
                                                <span>{formatDate(content.date)}</span>
                                                <span>{content.title}</span>
                                                <span>
                                                    <span className={tab.contents.length>1&&index!=0? "listSideButton":"listSideButton disable"} onClick={()=>{tab.contents.length>1&&index!=0&&move_contents(JSONidx,index,'up')}}>
                                                        <FontAwesomeIcon className="iconBtn" icon={faAngleUp} />
                                                    </span>
                                                    <span className={tab.contents.length>1&&index!=tab.contents.length-1? "listSideButton":"listSideButton disable"} onClick={()=>{tab.contents.length>1&&index!=tab.contents.length-1&&move_contents(JSONidx,index,'down')}}>
                                                        <FontAwesomeIcon className="iconBtn" icon={faAngleDown} />
                                                    </span>
                                                    <span className="listSideButton" onClick={()=>{remove_contents(content,JSONidx,index)}}>
                                                        <FontAwesomeIcon className="iconBtn" icon={faMinus} />
                                                    </span>
                                                </span>
                                            </p>
                                        )
                                    })}
                                    </div>
                                </div>
        
                                <div className="contentsListBox" style={{width:'23%'}}>
                                    <p>예배</p>
                                    <div className="listScrolWrap">
                                    {worshipList.map((worship,index)=>{
                                        return (
                                            <p className="contentsList" key={index}>
                                                {/* <span>{formatDate(worship.worshipDate)}</span> */}
                                                <span>{formatDate(worship.worshipDate)}</span>
                                                <span>{formatWorship(worship.sundayWorship)}</span>
                                                <span className="listSideButton" onClick={()=>{select_content(worship,JSONidx,'worship')}}>
                                                    <FontAwesomeIcon className="iconBtn" icon={faPlus} />    
                                                </span>    
                                            </p>
                                        )
                                    })}
                                    </div>
                                </div>

                                <div className="contentsListBox" style={{width:'37%'}}>
                                    <div>주보 콘텐츠</div>
                                    <div className="listScrolWrap">
                                    {bulletinContentsList.map((bulletinContent,index)=>{
                                        return (
                                            <p className="contentsList" key={index}>
                                                <span>{formatDate(bulletinContent.createdAt)}</span>
                                                <span>{bulletinContent.title}</span>
                                                <span className="listSideButton" onClick={()=>{select_content(bulletinContent,JSONidx,'bulletin_content')}}>
                                                    <FontAwesomeIcon className="iconBtn" icon={faPlus} />    
                                                </span>    
                                            </p>
                                        )
                                    })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>

                
                {Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)) &&
                <div className="bulletinEditorContentsRow contentsButtonBox">
                    <CustomButton 
                    title={"취소"}
                    onClick={() => {navigate(-1)}}
                    width={"auto"}
                    padding={"8px 16px"}
                    backgroundColor={COLORS.white}
                    border={"1px solid"+COLORS.black3} />
                    <CustomButton 
                    title={"등록"}
                    onClick={upload}
                    width={"auto"}
                    padding={"8px 16px"}
                    fontColor={validation? COLORS.white:COLORS.black9}
                    backgroundColor={validation? COLORS.black3:COLORS.grayED}
                    border={"1px solid"+validation? COLORS.black3:COLORS.grayED}/>
                </div>
                }

            </div>
        </div>
    );
}
export default BulletinEditorPage;