// src/components/CustomTable/CustomTable.js
import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useRowSelect, usePagination, useFilters } from 'react-table';

// res
import { ICONS } from '../../res'

import "./CustomTable.css"

const ListDotIcon = React.memo(({ id, isActive, onClick, onEdit, onDelete, role, roleType }) => {
    const iconSrc = isActive ? ICONS.LIST_DOT_ACTIVATE : ICONS.LIST_DOT_DEACTIVATE;
    const canDelete = Array.isArray(role) && role.some(r => roleType.includes(r));

    // onClick 핸들러에서 event 객체를 직접 사용
    return (
        <div className="listDotWrap" onClick={onClick}>
            <img className="listDot" src={iconSrc} />
            {isActive && (
                <div className="listOptionBox">
                    <p>URL 복사하기</p>
                    <p onClick={() => onEdit(id)}>자세히 보기</p>
                    {canDelete && <p onClick={()=>{onDelete(id)}}>삭제하기</p>}
                </div>
            )}
        </div>
    );
});

function CustomTable({ columns, data, onEdit, onDelete, listDot, filterName, filter, idField, userRole, roleType}) {
    const [activeId, setActiveId] = useState(null);

    useEffect(() => {
        // console.log("CustomTable",data);
    }, [data]);

    const newColumns = useMemo(() => {
        if (listDot) {
            return [
                ...columns,
                { 
                    id: 'listDot',
                    Cell: ({ row }) => (
                        <ListDotIcon
                            id={row.original[idField]}
                            isActive={activeId === row.original[idField]}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleListDot(row.original[idField]);
                            }}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            role={userRole}
                            roleType={roleType}
                        />
                    )
                }
            ];
        } else {
            return [...columns];
        }
    }, [columns, activeId, listDot, idField]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page, 
        prepareRow,
        pageCount,
        gotoPage,
        // 선택
        selectedFlatRows,
        setFilter,
    } = useTable({ columns: newColumns, data, initialState: { pageSize: data.length },},
        useFilters,
        usePagination,
        useRowSelect);

    // 바깥 클릭 감지를 위한 이벤트 리스너 설정
    useEffect(() => {
        const handleOutsideClick = () => {
            setActiveId(null); // 모든 active 상태 제거
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (filter === -1) {
            // 필터 해제
            setFilter(filterName, undefined);
        } else if (filter !== undefined) {
            // 필터 적용
            setFilter(filterName, filter === 1 ? "1" : "0");
        }
    }, [filter]);

    // handleListDot 함수 수정
    const handleListDot = (id) => {
        setActiveId(prevId => prevId === id ? null : id);
    };

    if(data.length==0) return (null);

    return (
        <div className="customTableWrap">
            {page.length==0? <div className="noData">데이터가 없습니다.</div> :
            <>
            {/* 테이블 */}
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            </>}
        </div>
    );
}

export default CustomTable;
