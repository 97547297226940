// src/components/CustomSelectBox/CustomSelectBox.js
import React, { useEffect, useState } from 'react';

import './CustomSelectBox.css'


function CustomSelectBox({ list, defaultList, defaultValue, callback, width, backgroundColor, border, fontColor, padding, disabled }) {

    const [selectedMenu, setSelectedMenu] = useState("-1");

    useEffect(()=>{
        setSelectedMenu(defaultValue);
    },[defaultValue]);

    const handleChange = async (event)=> {
        setSelectedMenu(event.target.value);
        callback(event.target.value);
    }

    return (
        <select className="CustomSelectBoxContainer" value={selectedMenu} onChange={handleChange} style={{ width:width, backgroundColor:backgroundColor, border:border, color:fontColor, padding: padding }} disabled={disabled}>
            {defaultList && <option value={defaultList}>{defaultList}</option>}
            {list.map((item, index) => (
                <option key={index} value={item}>{item}</option>
            ))}
        </select>
    );
}

export default CustomSelectBox;
