import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

// constants
import { COLORS } from '../../../constants'

// contexts
import { useAuth } from '../../../contexts/AuthContext';

// Components
import { CustomContentsTitle, CustomInputBox, CustomTextArea, CustomButton, CustomImageUpload, CustomImageMultiUpload, LoadingSpinner, CustomSelectBox } from "../../../components"

import './BulletinContentsEditorPage.css'

const ENV  = process.env.REACT_APP_NODE_ENV==='development' ? true : false;
const API_SERVER = process.env.REACT_APP_API_URLS;

const contentsTypeOrigin = [
    {id:1,title:"오늘의 예배"},
    {id:2,title:"일러스트 - 에센스툰"},
    {id:3,title:"예배 사진"},
    {id:4,title:"기획 - 소개"},
    {id:5,title:"기획 - 간증"},
    {id:7,title:"예배 - 안내"},
    {id:8,title:"아웃리치"},
    {id:9,title:"나눔"},
    {id:10,title:"알림"},
    {id:11,title:"특집 - 수련회"},
    {id:12,title:"섬기는 사람들"},
    {id:13,title:"일러스트 - Heal Young's Diary"},
    {id:14,title:"특집 - 차세대"},
    {id:15,title:"특집 - 선교"},
    {id:16,title:"특집 - 수련회"},
    {id:17,title:"나눔 - 기도회"},
    {id:18,title:"소개"},
    {id:19,title:"예배"},
    {id:22,title:"특집"},
    {id:23,title:"공동체"},
    {id:25,title:"일러스트"},
]

function BulletinContentsEditorPage(){

    const { isAuthenticated, checkAuth, accessToken, logout, userRoles } = useAuth();
    const roleType = ['admin','bulletin_editor'];

    const { id } = useParams();

    let navigate = useNavigate();
    const { communityId } = useAuth();
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState(false);
    
    const [uploadComplete, setUploadComplete] = useState(false);

    const [bulletinContentsTypeList,setBulletinContentsTypeList] = useState(contentsTypeOrigin);
    const [bulletinContentsTypeArray,setBulletinContentsTypeArray] = useState([]);

    const [editBulletinContents, setEditBulletinContents] = useState(null);

    const [bulletinContentsTitle,setBulletinContentsTitle] = useState(null);
    const [bulletinContentsTitleError,setBulletinContentsTitleError] = useState(null);
    const [bulletinContentsDesc,setBulletinContentsDesc] = useState(null);


    const [bulletinContentsType,setBulletinContentsType] = useState('-');
    const [bulletinContentsTypeId,setBulletinContentsTypeId] = useState(null);

    const [bulletinContentsButtonTitle,setBulletinContentsButtonTitle] = useState(null);
    const [bulletinContentsButtonUrl,setBulletinContentsButtonUrl] = useState(null);

    const [bulletinContentsContentsImageFiles, setBulletinContentsContentsImageFiles] = useState([]);
    const [bulletinContentsContentsImageUrls, setBulletinContentsContentsImageUrls] = useState([]);
    const [bulletinContentsContentsImageCount, setBulletinContentsContentsImageCount] = useState(0);
    const [bulletinContentsContentsImagelError, setBulletinContentsContentsImageError] = useState(null);

    const [bulletinContentsThumbnailImageFiles, setBulletinContentsThumbnailImageFiles] = useState([]);
    const [bulletinContentsThumbnailImageUrls, setBulletinContentsThumbnailImageUrls] = useState([]);
    const [bulletinContentsThumbnailImageCount, setBulletinContentsThumbnailImageCount] = useState(0);
    const [bulletinContentsThumbnailImagelError, setBulletinContentsThumbnailImageError] = useState(null);

    const [validation,setValidation] = useState(false);


    useEffect(()=>{
        setLoading(false);
        if(checkAuth()){
            // get_bulletin_contents_type();
            if(id) get_bulletin_contents(id);
        };
    },[]);

    useEffect(()=>{
        if(editBulletinContents){
            setBulletinContentsTitle(editBulletinContents.title);
            setBulletinContentsDesc(editBulletinContents.description);
            setBulletinContentsButtonTitle(editBulletinContents.buttonTitle);
            setBulletinContentsButtonUrl(editBulletinContents.buttonUrl);
            setBulletinContentsContentsImageUrls(editBulletinContents.imageUrl? stringToArray(editBulletinContents.imageUrl):[]);
            setBulletinContentsThumbnailImageUrls(editBulletinContents.thumbnailImageUrl? stringToArray(editBulletinContents.thumbnailImageUrl):[]);
        }
        setInitData(true)
    },[editBulletinContents]);
    
    useEffect(()=>{
        setBulletinContentsContentsImageCount(bulletinContentsContentsImageUrls.length);
    },[bulletinContentsContentsImageUrls]);
    
    useEffect(()=>{
        setBulletinContentsThumbnailImageCount(bulletinContentsThumbnailImageUrls.length);
    },[bulletinContentsThumbnailImageUrls]);
    
    useEffect(()=>{
        let result = dataValidate();
        if(result){
            setValidation(result); 
        }
        else {
            setValidation(false); 
        }
    },[bulletinContentsTitle])

    useEffect(()=>{
        if(bulletinContentsTypeList){
            let temp = [];
            for(let i=0;i<bulletinContentsTypeList.length;i++){
                temp.push(bulletinContentsTypeList[i].title);
            }
            setBulletinContentsTypeArray(temp);
        }
    },[bulletinContentsTypeList]);


    useEffect(()=>{
        if(uploadComplete){
            navigate('/bulletin/bulletinContents/list', { replace: true });
        }
    },[uploadComplete]);

    const dataValidate = () => {
        if(bulletinContentsTitle!=null&&bulletinContentsTitle.length>0) return true;
        return false;
    }

    const handleBulletinContentsTitle = (title) => {
        setBulletinContentsTitle(title);
        setBulletinContentsTitleError(null);
    }
    const handleBulletinContentsDesc = (desc) => {
        setBulletinContentsDesc(desc);
    }
    const handleSelectChange = (category) => {
        setBulletinContentsType(category)
        for(let i=0;i<bulletinContentsTypeList.length;i++){
            if(bulletinContentsTypeList[i].title===category) {
                setBulletinContentsTypeId(bulletinContentsTypeList[i].id);
            }
        }
    }
    const handleBulletinContentsButtonTitle = (title) => {
        setBulletinContentsButtonTitle(title);
    }
    const handleBulletinContentsButtonUrl = (url) => {
        setBulletinContentsButtonUrl(url);
    }
    const handleBulletinContentsContentsImageUrl = (file,url) => {
        setBulletinContentsContentsImageFiles(file);
        setBulletinContentsContentsImageUrls(url);
        setBulletinContentsContentsImageError(null);
    }
    const handleBulletinContentsThumbnailImageUrl = (file,url) => {
        setBulletinContentsThumbnailImageFiles(file);
        setBulletinContentsThumbnailImageUrls(url);
    }

    /** ************************************************************
     * Bulletin Contents Functions
     ************************************************************ */
    const get_bulletin_contents = async () => {
        try {
            const response = await axios.get(API_SERVER+'/bulletins/contents/'+id);
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log("[get_bulletin_contents]","[get]",API_SERVER+'/bulletins/contents/'+id,responseData[0]);
                setLoading(false);
                setEditBulletinContents(responseData[0]);
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        }
    }

    const get_bulletin_contents_type = async () => {
        try {
            const response = await axios.get(API_SERVER+'/bulletins/category');
            if (response.status === 200) {
                const responseData = response.data.data;
                if(ENV) console.log("[get_bulletin_contents_type]","[GET]",API_SERVER+'/bulletins/category',responseData);
                setBulletinContentsTypeList(responseData);
            } else {
                console.log("failed login");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
                logout(()=>{navigate("/login")});
            } else {
                console.log('로그인 실패: ' + error.message);
                logout(()=>{navigate("/login")});
            }
        }
    }

    const upload = async () => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 

        setLoading(true);
        let state = true;

        if(!communityId) {
            console.log("커뮤니티 정보가 없습니다.");
            return;
        }
        
        // 주보 컨텐츠 제목
        if(bulletinContentsTitle==null || bulletinContentsTitle.length==0) {
            setBulletinContentsTitleError("제목을 입력해주세요");
            state &= false;
        } else{
            setBulletinContentsTitleError(null);
            state &= true;
        }

        // 이미지 여부
        if(bulletinContentsContentsImageUrls==[] || bulletinContentsContentsImageUrls.length==0) {
            setBulletinContentsContentsImageError("이미지를 추가해주세요");
            state &= false;
        } else{
            setBulletinContentsContentsImageError(null);
            state &= true;
        } 

        if(!state) {
            setLoading(false);
            return;
        }

        if(state){

            let bulletinContentsContentsImageUrlsTemp = []
            if (bulletinContentsContentsImageUrls) {
                const bulletinContentsContentsImageUrlsWithIndex = bulletinContentsContentsImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                bulletinContentsContentsImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    bulletinContentsContentsImageUrlsTemp.push(temp);
                });
            }
            let bulletinContentsThumbnailImageUrlsTemp = []
            if (bulletinContentsThumbnailImageUrls) {
                const bulletinContentsThumbnailImageUrlsWithIndex = bulletinContentsThumbnailImageUrls.map((url, index) => ({
                    index: index,
                    url: url.startsWith("data:image") ? null : url
                }));
                bulletinContentsThumbnailImageUrlsWithIndex.forEach(({ index, url }) => {
                    const temp = {
                        index: index,
                        url: id? url:null
                    };
                    bulletinContentsThumbnailImageUrlsTemp.push(temp);
                });
            }

            const data = {
                communityId: communityId,
                content_type_id: bulletinContentsTypeId,
                title: bulletinContentsTitle,
                description: bulletinContentsDesc,
                buttonTitle: bulletinContentsButtonTitle,
                buttonUrl: bulletinContentsButtonUrl,
                imageUrl: bulletinContentsContentsImageUrlsTemp,
                thumbnailImageUrl: bulletinContentsThumbnailImageUrlsTemp,
            }

            // console.log("data",data);
            update_bulletin_contents(data);
        }

    }

    const update_bulletin_contents = async (data) => {
        if(!(Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)))) return; 
        try {
            let response;

            const formData = new FormData();
            formData.append("data", JSON.stringify(data)); 

            // 파일 데이터 추가 (배열로 추가)
            if (bulletinContentsContentsImageFiles) {
                bulletinContentsContentsImageFiles.forEach((file) => formData.append("imageFile", file));
            }
            if (bulletinContentsThumbnailImageFiles) {
                bulletinContentsThumbnailImageFiles.forEach((file) => formData.append("thumbnailImageFile", file));
            }

            if (id){
                if(ENV) console.log("[PUT]",API_SERVER+'/bulletins/contents/'+id,formData);
                response = await axios.put(API_SERVER+'/bulletins/contents/'+id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }
            else{
                if(ENV) console.log("[POST]",API_SERVER+'/bulletins/contents',formData);
                response = await axios.post(API_SERVER+'/bulletins/contents', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            }

            if (response.status === 200) {
                // const responseData = response.data.data;
                // console.log(responseData);
                // setWorshipList(responseData);
                setLoading(false);
                setUploadComplete(true);
            } else {
                console.log("failed to add worships");
            }
        } catch (error) {
            console.log("error",error);
            if (error.response) {
                console.log("error.response",error.response);
            } else if (error.request) {
                console.log('로그인 실패: 서버로부터 응답이 없습니다.');
            } else {
                console.log('로그인 실패: ' + error.message);
            }
        }
    }
    const stringToArray = (str) => {
        if (typeof str !== "string") return [];

        // 빈 배열 형태의 문자열 처리
        if (str.trim() === "[]") return [];

        // 정상적인 URL,URL,URL 형태의 문자열을 배열로 변환
        return str.split(",").map((item) => item.trim());
    }

    if(!initData) return;
    
    return (
        <div className="contentsPage bulletinContentsEditorWrap">
            <div className="bulletinContentsEditorContainer">
                {/* 제목 */}
                <div className="bulletinContentsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"제목"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={true}/>
                    <CustomInputBox 
                    callback={(title)=> {handleBulletinContentsTitle(title)}}
                    data={bulletinContentsTitle}
                    placeholder={"제목 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    textLength={30}
                    errorText={bulletinContentsTitleError}/>
                </div>

                {/* 설명 */}
                <div className="bulletinContentsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"설명"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomTextArea 
                    callback={(desc)=>{handleBulletinContentsDesc(desc)}}
                    data={bulletinContentsDesc? bulletinContentsDesc: ""}
                    placeholder={"설명 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    line={10}
                    textLength={2000}/>
                </div>

                {/* 컨텐츠 타입 */}
                <div className="bulletinContentsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"컨텐츠 타입"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <br/>
                    <CustomSelectBox 
                    list={bulletinContentsTypeArray}
                    defaultList={'-'}
                    defaultValue={bulletinContentsType}
                    width={'200px'}
                    callback={(value) => handleSelectChange(value)}
                    padding={'4px 8px'}
                    border={'1px solid #DDDDDD'}
                    disabled={true}/>
                </div>



                {/* 버튼 제목 */}
                <div className="bulletinContentsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"버튼 제목"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomInputBox 
                    callback={(buttonTitle)=>{handleBulletinContentsButtonTitle(buttonTitle)}}
                    data={bulletinContentsButtonTitle}
                    placeholder={"버튼 제목 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}
                    textLength={20}/>
                </div>

                {/* 버튼 URL */}
                <div className="bulletinContentsEditorContentsRow">
                    <CustomContentsTitle 
                    title={"버튼 URL"}
                    margin={'8px'}
                    fontSize={'14px'}
                    fontColor={COLORS.black3}
                    fontWeight={500}
                    require={false}/>
                    <CustomInputBox 
                    callback={(url)=>{handleBulletinContentsButtonUrl(url)}}
                    data={bulletinContentsButtonUrl}
                    placeholder={"버튼 URL 입력"}
                    width={"435px"}
                    border={"1px solid "+ COLORS.grayD}
                    fontColor={COLORS.black3}
                    padding={"8px"}/>
                </div>

                {/* 이미지 리스트 */}
                <div className="bulletinContentsEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"이미지 리스트"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={bulletinContentsContentsImageCount}
                            lengthMax={10}
                            require={true} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handleBulletinContentsContentsImageUrl(images,urls)}}
                            imagesUrl={bulletinContentsContentsImageUrls}
                            imageFile={bulletinContentsContentsImageFiles}
                            id={"bulletinContentsContentsImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={10}
                            errorText={bulletinContentsContentsImagelError} />
                        </span>
                    </div>
                </div>

                {/* 썸네일 이미지 */}
                <div className="bulletinContentsEditorContentsRow">
                    <div style={{ display:'flex',flexDirection:'row' }}>
                        <span style={{width:'136px'}}>
                            <CustomContentsTitle 
                            title={"썸네일 이미지"}
                            margin={'8px'}
                            fontSize={'14px'}
                            fontColor={COLORS.black3}
                            fontWeight={500}
                            length={bulletinContentsThumbnailImageCount}
                            lengthMax={1}
                            require={false} />
                            <CustomImageMultiUpload 
                            callback={(images,urls)=>{handleBulletinContentsThumbnailImageUrl(images,urls)}}
                            imagesUrl={bulletinContentsThumbnailImageUrls}
                            imageFile={bulletinContentsThumbnailImageFiles}
                            id={"bulletinContentsThumbnailImage"}
                            width={120}
                            height={120}
                            border={"1px solid "+ COLORS.grayD}
                            fontColor={COLORS.black9}
                            maxImages={1}
                            errorText={bulletinContentsThumbnailImagelError} />
                        </span>
                    </div>
                </div>


                {Array.isArray(userRoles) && userRoles.some(r => roleType.includes(r)) &&
                <div className="bulletinContentsEditorContentsRow contentsButtonBox">
                    <CustomButton 
                    title={"취소"}
                    onClick={() => {navigate(-1)}}
                    width={"auto"}
                    padding={"8px 16px"}
                    backgroundColor={COLORS.white}
                    border={"1px solid"+COLORS.black3} />
                    <CustomButton 
                    title={"등록"}
                    onClick={upload}
                    width={"auto"}
                    padding={"8px 16px"}
                    fontColor={validation? COLORS.white:COLORS.black9}
                    backgroundColor={validation? COLORS.black3:COLORS.grayED}
                    border={"1px solid"+validation? COLORS.black3:COLORS.grayED}/>
                </div>
                }
            </div>
        </div>
    );
}
export default BulletinContentsEditorPage;