// src/components/CustomTitle/CustomTitle.js
import React from 'react';

// components
import { CustomButton } from "../"

import './CustomTitle.css'


function CustomTitle({ 
    title, titleSize, titleWeight, titleColor, 
    buttonTitle, buttonOnClick, buttonWidth, buttonBackgroundColor, buttonFontColor, buttonPadding, buttonBorder, }) {
    return (
        <div className="CustomTitleContainer">
            <div className="TopTitle" style={{ fontSize:titleSize, fontWeight:titleWeight, color:titleColor }}>
                {title}
            </div>
            {buttonTitle &&
            <CustomButton 
                title={buttonTitle} 
                onClick={buttonOnClick} 
                width={buttonWidth} 
                backgroundColor={buttonBackgroundColor} 
                fontColor={buttonFontColor} 
                padding={buttonPadding}
                border={buttonBorder} />
            }
        </div>
    );
}

export default CustomTitle;
