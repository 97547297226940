// src/components/CustomContentsTitle/CustomContentsTitle.js
import React, { useEffect, useState } from 'react';

// constants
import { COLORS } from '../../constants'

function CustomContentsTitle({ title, margin, fontSize, fontColor, fontWeight, require, length, lengthMax, styles }) {
    return (
        <span>   
            <span style={{ display:'inline-block', marginBottom:margin, fontSize:fontSize, fontColor:fontColor, fontWeight:fontWeight, ...styles }}>{title}</span>
            {require && <span style={{color:'red',}}>*</span>}
            {lengthMax && <span style={{display:'inline-block', color:COLORS.black9, fontSize:'14px', marginLeft:'8px'}}>{length}/{lengthMax}</span>}
        </span>
    );
}

export default CustomContentsTitle;
