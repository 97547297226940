// src/components/CustomTextArea/CustomTextArea.js
import React, { useEffect, useState } from 'react';

import './CustomTextArea.css'


function CustomTextArea({ callback, data, placeholder, width, backgroundColor, border, fontColor, padding, line, textLength, errorText }) {

    const [text, setText] = useState(data? data:'');

    useEffect(()=>{
        setText(data);
    },[data])

    const handleChange = async (event)=> {
        setText(event.target.value);
        callback(event.target.value);
    }

    return (
        <>
        <div className="customTextArea" style={{ 
        width:width, 
        backgroundColor:backgroundColor, 
        border:errorText? "1px solid red":border,
        color:fontColor, 
        padding: padding, 
        borderRadius:'4px', 
        display:'flex', 
        flexDirection:'column',
        justifyContent:'space-between',
        boxSizing:'border-box' }} >   
            <textarea
            type="text"
            value={text}
            className="CustomSelectBoxContainer" 
            onChange={handleChange} 
            style={{padding:0,border:'none',outline:'none', flex:1}}
            rows={line}
            maxLength={textLength}
            resize={'none'}
            placeholder={placeholder}
            />
            {textLength && <span className="textLengthBox"><span>{text? text.length:0}</span>/{textLength}자</span>}
        </div>
        {errorText && <p style={{color:'red', fontSize:'12px', marginTop:'8px'}}>{errorText}</p>}
        </>
    );
}

export default CustomTextArea;
