import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTable, useRowSelect, usePagination } from 'react-table';
import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Components
import { CustomTitle, HorizenLine } from "../../components"


import './PushPage.css'


function PushPage(){
    return (
        <div className="contentsPage pushWrap">
            
            {/* Title Container */}
            <CustomTitle 
                title="푸시 알람" 
                titleSize="24px"
                titleWeight="500"
            />
            <HorizenLine color="#999999" />
        </div>
    );
}
export default PushPage;


